$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  border-radius: 12px;
  overflow: hidden;
  min-height: 70px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  // padding: 0 52px;
  .img {
    // border-radius: 12px;
    width: 100%;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2302f;
    border-radius: 0 0 12px 12px;
    // padding: 6px;
    .shopInfo {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border-radius: 0 12px 0 12px;
      border: 4px solid #f2302f;
      padding: 22px 16px;
      box-sizing: border-box;
      .shopName {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #333333;
        line-height: 29px;
        text-align: left;
        font-style: normal;
        // 一行文字省略号
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
      .shopAddress {
        display: flex;
        align-items: center;
        .addressIcon {
          width: 24px;
          height: 24px;
          color: #86909c;
          margin-right: 6px;
        }
        .shopDistance {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #86909c;
          line-height: 25px;
          text-align: left;
          font-style: normal;
        }
      }
    }
    .discount {
      display: flex;
      align-items: baseline;
      padding: 0 8px 0 16px;
      width: 134px;
      flex: none;
      font-weight: bold;
      justify-content: center;
      .discountNum {
        font-family: PingFangSC, PingFang SC;
        font-size: 56px;
        color: #ffffff;
        line-height: 78px;
        text-align: left;
        font-style: normal;
      }
      .discountUnit {
        font-family: PingFangSC, PingFang SC;
        // font-weight: 400;
        font-size: 24px;
        color: #ffffff;
        line-height: 33px;
        text-align: left;
        font-style: normal;
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.module {
  .moduleBody {
    padding: 40px;
    border-radius: 24px 24px 0px 0px;
    box-sizing: border-box;
    .title {
      margin-top: 40px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 36px;
      color: #131415;
      line-height: 50px;
      text-align: center;
      margin-bottom: 16px;
    }
    .subTitle {
      text-align: center;
      .label {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #86909c;
        text-align: center;
        line-height: 33px;
        margin-right: 8px;
      }
      .phone {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #1d2129;
        line-height: 33px;
      }
    }
    .passcode {
      margin-top: 80px;
      --cell-size: 96px;
      --border-color: transparent;
      --cell-gap: 20px;
      --border-radius: 16px;
      --adm-color-background: #f3f5f7;
    }
    .passcodeFooter {
      margin-top: 12px;
      text-align: right;
      margin-bottom: 120px;
      .timer {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #86909c;
        line-height: 33px;
        .timerNum {
          margin-left: 8px;
        }
      }
    }
  }
}
.input {
  position: fixed;
  top: -10000000000px;
  z-index: -999999999999999;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.form {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;

  .item {
    ---prefix-width: 140px !important;
    text-align: left;
  }
  :global {
    .adm-list-card {
      margin: 0 0 16px;
      flex: 1;
      display: flex;
      flex-direction: column;
      &:last-child {
        margin-bottom: 0;
      }
      .adm-list-body {
        flex: 1;
      }
    }
  }
}
.footer {
  width: 100%;

  position: sticky;
  bottom: env(safe-area-inset-bottom);
}

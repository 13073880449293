$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-color: #F4F9FF;
  overflow: hidden;

  .rowContainer {
    padding: 32px 24px;
  }

  .inputContainer {
    background-color: #fff;
    border-radius: 32px;
    height: 104px;
    padding: 0 32px;
    font-size: 28px;

    .input {
      height: 104px;
    }
  }

  .saveButton {
    margin-top: 64px;
    height: 88px;
    font-size: 28px;
    white-space: nowrap;
    background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
    border-radius: 46px;
    border: none;

    &:active {
      opacity: 0.5;
    }
  }

  
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  min-height: 100vh;
  color: #1D2129;
  background-color: #F4F9FF;
  text-align: left;
  overflow: hidden;
}

.infoList {
  border-radius: 32px;
  background-color: #fff;
  overflow: hidden;
  margin: 34px 24px;
  
  :global {

    .adm-list-body {
      border: none;
    }
    .adm-list-item {
      padding: 0 32px;
    }
    .adm-list-item-content-prefix {
      padding-right: 16px;
    }
    
    .adm-list-item-content-main {
      font-size: 28px;
      padding: 32px 0;
    }

    .adm-list-item-content {
      border: none;
      padding: 0;
    }
  }
}

.label {
  font-size: 16px;
  color: #333;
}

.avatarWrapper {
  display: flex;
  align-items: center;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
}

.valueWrapper {
  display: flex;
  align-items: center;
}

.value {
  font-size: 24px;
  color: #85909D;
  margin-right: 8px;
}

.arrowIcon {
  color: #4E5969;
  font-size: 24px;
}
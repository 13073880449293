$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.loginContainer {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #1677ff 0%, #1677ff 40%, #f5f5f5 40%, #f5f5f5 100%);
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom;
}

.welcomeSection {
  color: #ffffff;
  padding: 114px 0 60px;
  text-align: left;
}

.greeting {
  font-size: 64px;
  font-weight: 500;
  margin: 0 0 16px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.greetingIcon {
  width: 36px;
  height: 36px;
  margin-left: 16px;
  object-fit: contain;
}

.welcomeText {
  font-size: 40px;
  font-weight: 500;
  margin: 0;
  padding-left: 16px;
}

.appName {
  display: inline-block;
  color: #ffffff;
  padding: 8px 20px;
  font-size: 36px;
  border-radius: 34px 34px 34px 0px;
  font-weight: 500;
  margin-right: 12px;
}

.loginCard {
  background: #E7E9EF;
  border-radius: 24px;
  // padding: 0 0 24px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
  // margin-bottom: 24px;
  position: relative;

  :global {
    .adm-form {
      padding: 80px 40px 24px;
      background: #fff;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;

      .adm-list-body {
        border: none;
      }
    }
    .adm-form-item {
      margin-bottom: 40px;
      padding-left: 0;
      background: #F5F7FB;
      border-radius: 52px;
      height: 104px;
    }

    .adm-list-item-content-main {
      padding: 0;
    }

    .adm-list-item-content {
      border: none;
    }

    .adm-input {
      font-size: 28px;
      color: #86909C;
      line-height: 104px;
      height: 104px;
    }

    .adm-input-element {
      height: 48px;
      font-size: 28px;
    }

    .adm-form-item-extra {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }

    .adm-form-footer {
      padding: 40px 0px;
    }

    .adm-form-item-feedback-error {
      margin: 0;
    }
  }
}

.tabContainer {
  display: flex;
  position: relative;
  z-index: 2;
  background-color: #f5f5f5;
  // overflow: hidden;
}

.tabItem {
  flex: 1;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  // background-color: #E7E9EF;
  color: #1D2129;
  position: relative;
  
  &.active {
    font-weight: 600;
    opacity: 1;
    background: #ffffff;
    border-radius: 20px 20px 0 0;
    // box-shadow: 44px 60px 0 #fff, -44px 60px 0 0 fff;    
    // box-shadow: 30px 70px 0 black, 20px 60px 0 0 black, -20px 20px 0 0 #fff, -44px 60px 0 0 #fff;   
    box-shadow: 34px 70px 0 #fff, 20px 30px 0 0 #fff, -20px 20px 0 0 #fff, -44px 60px 0 0 #fff;   


    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      bottom: 0;
      height: 100px;
      width: 40px;
      border-top-left-radius: 24px;
      background-color: #fff;
      transform: skewX(-20deg);
    }

    &::after {
      content: '';
      position: absolute;
      right: -10px;
      bottom: 0;
      height: 100px;
      width: 30px;
      border-top-right-radius: 24px;
      background-color: #fff;
      transform: skewX(20deg);
    }
  }

  &.inactive {

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: -8px;
      bottom: 0;
      width: 30px;
      height: 100px;
      background: #E7E9EF;
      // background: red;
      border-bottom-left-radius: 240px;
      transform: skewX(20deg);
    }

    &::after {
      z-index: 1;
      content: '';
      position: absolute;
      right: 4px;
      bottom: 0;
      width: 30px;
      height: 100px;
      background: #E7E9EF;
      border-bottom-right-radius: 24px;
      transform: skewX(-20deg);
    }
  }
}

.tabsContainer {
  display: flex;
  overflow: hidden;
}

.loginButton {
  height: 88px;
  font-size: 28px;
  font-weight: 500;
  margin-top: 57px;
  border-radius: 46px;
  border: none;

  &:active {
    opacity: 0.5;
  }
}

.passwordLoginForm {
  border-top-right-radius: 24px;
}

.smsLoginForm {
  border-top-left-radius: 24px;
}


.actionLinks {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 28px;
}

.registerLink {
  color: #1777FF;
}

.forgotPasswordLink {
  color: #1777FF;
}

.verifyCodeButton {
  display: flex;
  align-items: center;
  height: 30px;
  border-left: 2px solid #C9CDD4;

}

.customInput {
  padding-left: 12px !important;
  font-size: 28px;
}

.phoneInputWrapper {
  display: flex;
  align-items: center;
}

.phoneIcon {
  margin: 0 8px 0 32px;
  flex-shrink: 0;
  color: #86909C;
}

.smsCodeInput {
  :global {
    .adm-input-element {
      margin-right: 100px;
    }
  }
}
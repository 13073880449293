$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.button {
}
.buttonPS {
  --background-color: linear-gradient(
    269deg,
    #33afff 0%,
    #1777ff 100%
  ) !important;
  background: var(--background-color);
  border-radius: 46px;
  padding: 24px 88px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  line-height: 40px;
  border: none;
  &::before {
    border: none;
  }
}
.buttonPN {
  --font-color: #1879ff;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: var(--font-color);
  line-height: 33px;
  padding: 0;
  &::before {
    border: none;
  }
}

.black {
  --background-color: #06233a !important;
}
// .black,
// .buttonPN {
//   --font-color: #1879ff;
// }

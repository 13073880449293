$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  display: flex;
  flex-direction: column;

  background-color: #fff;
  flex: 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  margin-bottom: 32px;
  .tabs {
    flex: 1;
    --active-line-color: rgba(24, 121, 255, 1);
    --active-line-border-radius: 4px;
    --active-line-height: 8px;
    --active-title-color: #242931;
    --content-padding: 42px 32px;
    --fixed-active-line-width: 40px;
    --title-font-size: 28px;
    :global {
      .adm-tabs-header {
        border-bottom: none;
      }
      .adm-tabs-tab {
        padding-top: 32px;
      }
      .adm-tabs-tab-active {
        --title-font-size: 32px;
        font-weight: bold;
      }
    }
  }
  .footer {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #86909c;
    line-height: 33px;
    text-align: center;
    font-style: normal;
    padding-bottom: 17px;
  }
}
.footer {
  width: 100%;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.live {
  width: 700px;
  height: auto;
  margin: 0 auto;
  margin-top: 30px;
}

.header {
  text-align: left;
  margin-bottom: 20px;
  .icon {
    width: 32px;
    height: 32px;
  }

  span {
    color: #333;
    font-size: 30px;
    margin-left: 8px;
    font-weight: bolder;
  }
}

.content {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  .item {
    width: 335px;
    height: auto;
    border-radius: 12px;
    overflow: hidden;
    background: #fff;
    display: inline-block;
    // margin-right: 15px;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 460px;
    }

    .font{
        color: #1d2129;
        font-size: 28px;
        padding: 15px;
        text-align: left;
        font-weight: bolder;
        max-height: 115px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  height: 120px;
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 30px;
  align-items: center;

  .actions {
    width: 500px;
    text-align: right;
    line-height: 120px;
    color: #131415;

    .text {
      font-size: 24px;
    }
    .amount {
      font-size: 45px;
      font-weight: bolder;
      margin-right: 20px;
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  height: 100vh;
  box-sizing: border-box;
  background-color: #f5f5f5;
  overflow: auto;
  scroll-behavior: smooth;
}
.pageItem {
  padding: 16px 24px 0px;
  background-color: #fff;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.tips {
  display: flex;
  align-items: center;
  //   justify-content: center;
  background: rgb(15, 198, 194, 0.1);
  border-radius: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #09aeaa;
  line-height: 28px;
  text-align: right;
  font-style: normal;
  padding: 2px 12px;
  .icon {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  border-radius: 24px;
  background: #fff;
  height: 100px;
  font-size: 28px;
  text-align: left;
  box-sizing: border-box;
  padding: 15px 25px;
  line-height: 70px;
  

  .label {
    display: inline-block;
    color: #4e5969;
    width: 180px;
  }
  .value {
    display: inline-block;
    color: #ff4b33;
  }
}

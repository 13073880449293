$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.bannerList {
  padding: 36px 24px 0px;
  box-sizing: border-box;
  .bannerImg {
    width: 100%;
    // height: 280px;
    border-radius: 16px;
  }
}
.hideIndicator {
  :global {
    .adm-swiper-indicator {
      display: none;
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  min-height: 280px;
  border-radius: 24px;
  background: #fff;
  text-align: left;
  box-sizing: border-box;
  padding: 15px 25px;
  margin-bottom: 20px;

  .title {
    color: #1d2129;
    font-size: 28px;
    font-weight: bolder;
    padding: 10px 0px;
  }

  .content {
    color: #86909c;
    font-size: 24px;
    padding-top: 10px;
  }
  .date {
    color: #86909c;
    font-size: 24px;
    padding-top: 5px;
    // padding: 10px 0px;
  }

  .action {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 15px;

    .check {
      color: #131415;
      font-size: 28px;
      --icon-size: 36px;
      --font-size: 28px
    }

    span {
      color: #ff4b33;
      font-size: 28px;
      font-weight: bolder;
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  position: relative;
  flex: 1;
  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 28px;
    color: #86909c;
    line-height: 40px;
    .searchIcon {
      width: 36px;
      height: 36px;
    }
  }
  .input {
    background: #eaebed;
    border-radius: 32px;
    height: 64px;
    --text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 28px;
    color: #86909c;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

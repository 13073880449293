$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {

  :global(.adm-list-body) {
    border: none;
  }

  :global(.adm-list-item) {
    border-bottom: 2px solid #F2F3F5;
  }

  :global(.adm-list-item-content) {
    border: none;
    padding: 0;
  }

  :global(.adm-list-item-content-prefix) {
    width: 160px;
  }

  :global(.adm-form-item-label) {
    font-size: 30px;
  }

  :global(.adm-input-element) {
    font-size: 30px;
  }

  :global(.adm-input-element) {
    font-size: 30px;
  }

  :global(.adm-form-footer) {
    padding: 20px 0;
  }

  .phone {
    font-size: 30px;
  }

  .getCodeBtn {
    font-size: 30px;
    color: #1777FF;
  }

  .btnContainer{
    margin-top: 100px;
    
    .btn {
      width: 100% !important;
      height: 88px;
      font-size: 28px;
      font-weight: 500;
      color: #fff;
      background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
      border-radius: 46px;
      border: none;
    }
  }
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.table {
  --col-gap: 20px;
  background: #ffffff;
  border-radius: 24px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  .tableHeader {
    position: sticky;
    top: 0;
    padding-bottom: 24px;
    padding-top: 32px;
    padding: 24px 32px 32px;
    --gap: var(--col-gap);
    .tableHeaderItem {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #86909c;
      line-height: 33px;
      text-align: left;
      font-style: normal;
    }
  }
  .tableBody {
    .tableRow {
      padding: 0px 32px;

      &:active {
        background: #f2f3f5;
      }

      .tableRowContent {
        --gap: var(--col-gap);
        padding: 24px 0px;

        border-bottom: 1px solid #f2f3f5;

        &:first-child {
          border-top: 1px solid #f2f3f5;
        }
        &:last-child {
          border-bottom: none;
        }
        .tableCol {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .icon {
            width: 24px;
            height: 24px;
            color: #86909c;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  min-height: 100vh;
  color: #1D2129;
  background-color: #F4F9FF;
  text-align: left;
  overflow: hidden;
}

.infoList {
  border-radius: 32px;
  background-color: #fff;
  overflow: hidden;
  margin: 34px 24px;
  
  :global {

    .adm-list-body {
      border: none;
    }
    .adm-list-item {
      padding: 0 32px;
    }
    .adm-list-item-content-prefix {
      padding-right: 16px;
    }
    
    .adm-list-item-content-main {
      font-size: 28px;
      padding: 32px 0;
    }

    .adm-list-item-content {
      border: none;
      padding: 0;
    }
  }
}

.label {
  font-size: 16px;
  color: #333;
}

.avatarWrapper {
  display: flex;
  align-items: center;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
}

.valueWrapper {
  display: flex;
  align-items: center;
}

.value {
  font-size: 24px;
  color: #85909D;
  margin-right: 8px;
}

.arrowIcon {
  color: #4E5969;
  font-size: 24px;
}

.bottomFixedContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F4F9FF;
  padding: 24px 24px 32px;
}

.verifyButtons {
  .verifyButton {
    background: #F4F9FF;
    border: 2px solid #F2302F;
    height: 88px;
    border-radius: 46px;
    font-size: 28px;
    font-weight: 500;
    color: #F2302F;
    &:active {
      opacity: 0.5;
    }
  }
}

.contentContainer {
  flex: 1;
  padding-bottom: 240px; /* Add enough padding to prevent content from being hidden behind fixed bottom container */
} 

.dialogGrid {
  padding-top: 40px;
}

.continueBtn {
  width: 230px;
  height: 88px;
  background: #FFFFFF;
  border-radius: 46px;
  border: 2px solid #E5E6EB;
  font-weight: 500;
  font-size: 28px;
  color: #1D2129;
  line-height: 40px;
}

.backBtn {
  width: 230px;
  height: 88px;
  background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
  border-radius: 46px;
  font-weight: 500;
  font-size: 28px;
  color: #FFFFFF;
  line-height: 40px;
}
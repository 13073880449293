$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #F4F9FF;
  background-image: url('https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/o94qerm4i7.png');
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;
}

.info {
  padding-top: 60px;
  padding-bottom: 64px;
  text-align: center;
  color: #FFF;
}

.name {
  margin-bottom: 16px;
  line-height: 50px;
  font-size: 36px;
  font-weight: 500;
}

.desc {
  margin-bottom: 48px;
  line-height: 34px;
  font-size: 24px;
}

.avatar {
  width: 96px;
  height: 96px;
  margin: 0 auto 16px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.avatarLoading,
.avatarText {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarText {
  font-size: 48px;
}

.phone {
  line-height: 44px;
  font-size: 32px;
}

.form {
  box-sizing: border-box;
  width: 702px;
  margin: 0 auto;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  text-align: left;
  :global(.adm-form-footer) {
    // display: none;
  }
}

.formTitle {
  text-align: left;
  color: #1D2129;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

.btnContainer {
  position: fixed;
  bottom: 50px;
  left: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 208px;
  padding: 0 24px;
}

.primaryBtn,
.secondaryBtn {
  height: 88px;
  margin-bottom: 16px;
}

.primaryBtn {
  border: 0 none;
  background: linear-gradient(269deg, #33AFFF 0%, #1777FF 100%);
}

.secondaryBtn {
  border-color: #C9CDD4;
}

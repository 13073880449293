$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.content {
  position: relative;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    z-index: 10000;
  }
  .cropContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    // overflow-y: auto;
    .crop {
      width: 80%;
      .imageContainer {
        .image {
          width: 100%;
        }
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.formItem {
  padding-left: 32px;
  padding-right: 32px;
  :global {
    --align-items: baseline;
    .adm-list-item-content {
      padding-right: 0;
      .adm-list-item-content-prefix {
        padding: 28px 0;
        .adm-form-item-label {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 28px;
          color: #4e5969;
          line-height: 40px;
          height: auto;
          text-align: left;
        }
      }
      .adm-list-item-content-main {
        padding: 28px 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #1d2129;
        line-height: 40px;
        .adm-input-element {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 28px;
          color: #1d2129;
          line-height: 40px;
          &::placeholder {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 28px;
            color: #86909c;
            line-height: 40px;
          }
        }
      }
    }
  }
}

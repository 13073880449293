$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.card {
  background: #ffffff;
  border-radius: 24px;
  width: 100%;
  box-sizing: border-box;
  .header {
    width: 100%;
    border-radius: 24px 24px 0 0;

    display: flex;
    padding: 24px 32px;
    justify-content: space-between;
    align-items: center;
    // padding-bottom: 24px;
    // padding-top: 24px;
    box-sizing: border-box;

    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #1d2129;
      line-height: 40px;
      text-align: left;
      font-style: normal;
    }
    .actionIcon {
      width: 36px;
      height: 36px;
      transform: rotate(180deg);
    }
  }
  .actionHeader {
    &:active {
      background: #f5f7fa;
    }
  }
  .body {
    padding: 0px 32px 32px;
  }
  .noHeaderBody {
    padding-top: 32px;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.step {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 72px;
  .stepItem {
    flex: 1;
    text-align: center;
    .lineBox {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 28px;
      .line {
        flex: 1;
        height: 12px;
        background: #fff;
        opacity: 0.2;
      }
      .point {
        display: none;
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      line-height: 33px;
      height: 40px;
    }
  }
  .active {
    .lineBox {
      .point {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 0px 0px 12px rgb(255, 255, 255, 0.45);
      }
    }
    .content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #ffffff;
      line-height: 40px;
    }
  }
}

.stepItem:last-child .lineBox .line:last-child {
  display: none;
}
.stepItem:first-child .lineBox .line:first-child {
  display: none;
}
.stepItem:last-child .lineBox .line:first-child {
  border-top-right-radius: 6px;
}
.stepItem:first-child .lineBox .line:last-child {
  border-top-left-radius: 6px;
}
.stepItem:last-child .content {
  text-align: right;
  //   margin-left: 10px;
  transform: translate(calc(50%), 0);
}
.stepItem:first-child .content {
  text-align: left;
  transform: translate(calc(-50%), 0);
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  height: 250px;
  border-radius: 24px;
  background: #fff;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px;
  text-align: left;

  .content {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    .info {
      color: #86909c;
      font-size: 24px;
      line-height: 40px;
    }
    .amount {
      color: #131415;
      font-size: 40px;
    }

    .money {
      font-size: 24px;
    }
  }

  .footer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    span {
      color: #86909c;
    }

    .padding {
      color: #1879ff;
    }
  }
}

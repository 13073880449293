$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.content {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .top {
    flex: 1;
    background-color: #fff;
    padding: 48px 24px 80px;
    margin-bottom: 16px;

    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.03);
    border-radius: 16px;

    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 36px;
      color: #1d2129;
      line-height: 50px;
      text-align: center;
      margin-bottom: 80px;
    }
    .protocol {
      --gap: 48px;
      .protocolTop {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #1d2129;
        line-height: 44px;
      }
      .protocolItem {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #1d2129;
        line-height: 50px;
      }
    }
  }
  .bottom {
    margin-bottom: 32px;
    :global {
      .adm-list-card {
        margin: 0;
      }
    }
  }
}
.footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  position: sticky;
  bottom: env(safe-area-inset-bottom);
  .prevButton {
    flex: 1;
    border-radius: 46px;
    padding: 24px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    border: none;
    margin-right: 20px;
    &::before {
      border: none;
      display: none;
    }
  }
  .button {
    flex: 2;
  }
}

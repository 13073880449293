$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  background-color: #fff;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  .form {
    padding: 24px 32px 0;
    --prefix-width: 196px;

    :global {
      .adm-list-body {
        border-top: none;
      }
    }
  }
  .footer {
    margin-top: 120px;
    padding: 0 24px;
  }
  .formItem {
    --label-color: #1d2129;
  }
}

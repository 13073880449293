$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 700px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  color: #4e5969;
  font-size: 28px;
  position: relative;

  svg {
    margin-left: 5px;
  }
}

.filter {
  width: 750px;
  height: 950px;
  margin: 0px;
  padding: 25px;
  color: #4e5969;
  font-size: 24px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;

  li {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  .input {
    margin: 10px 0px 30px 0px;
  }

  .footer {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin-top: 80px;
  }

  .button {
    width: 45%;
  }
}

.dropdown {
  max-width: 750px;


}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.header {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 36px;
  color: #131415;
  line-height: 50px;
  text-align: center;
  font-style: normal;
  padding: 64px 48px 24px;
}
.body {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 28px;
  color: #86909c;
  line-height: 40px;
  text-align: left;
  font-style: normal;
  padding: 0 48px 48px;
}
.footer {
  padding: 0 48px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cancel {
    border-radius: 46px;
    padding: 24px 88px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    // border: none;
  }
}
.modal {
  --min-width: 580px;
}

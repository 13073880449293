$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-color: #F4F9FF;
  overflow: hidden;
}

.rowContainer {
  height: 750px;
  overflow: hidden;
}

.bottomFixedContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 40px 24px 48px;
  border-top: 2px solid #F2F3F5;
}

.verifyButtons {
  margin-bottom: 24px;
  height: 88px;
  border-radius: 46px;
  font-size: 36px;
}
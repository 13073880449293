$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.App {
  height: 100%;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
}
.toTop {
  bottom: calc(150px + env(safe-area-inset-bottom));
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  text-align: left;
  background: #fff;
  min-height: 100vh;
}

.btnContainer{
  margin-top: 100px;
  padding: 0 20px;
  
  .btn {
    width: 100% !important;
    height: 88px;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
    border-radius: 46px;
    border: none;
  }
}

.dialogGrid {
  padding-top: 40px;
}

.continueBtn {
  width: 230px;
  height: 88px;
  background: #FFFFFF;
  border-radius: 46px;
  border: 2px solid #E5E6EB;
  font-weight: 500;
  font-size: 28px;
  color: #1D2129;
  line-height: 40px;
}

.backBtn {
  width: 230px;
  height: 88px;
  background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
  border-radius: 46px;
  font-weight: 500;
  font-size: 28px;
  color: #FFFFFF;
  line-height: 40px;
}

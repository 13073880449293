$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.layout {
  height: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
  // overfslow: hidden;
  scroll-behavior: smooth;
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // flex-direction: column;
  overscroll-behavior-y: none;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 102px);
    box-sizing: border-box;
    overflow-y: auto;
    // flex: 1;
    // ios 关闭粘性
    overscroll-behavior-y: none;
    .container {
      position: relative;
      flex: 1;
      width: 100%;
      height: 100%;
    }
  }
  .tabBar {
    position: sticky;
    width: 100%;
    height: 102px;
    bottom: 0px;
    background-color: #fff;
    box-shadow: 0px -12px 20px 0px rgba(0, 0, 0, 0.03);
    :global {
      .adm-tab-bar-item-icon {
        height: 48px;
      }
      .adm-tab-bar-item-title-with-icon {
        margin-top: 1px;
      }
    }
    .item {
      padding: 16px 8px 8px;
    }
    .icon {
      width: 48px;
      height: 48px;
      color: #1d2129;

      display: flex;
    }
    .activeIcon {
      color: #1777ff;
    }
    .title {
      // margin-top: 8px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #1d2129;
      line-height: 28px;
    }
    .activeTitle {
      font-weight: 500;
      color: #1777ff;
    }
  }
}
.toTop {
  // bottom: 150px;
  bottom: calc(150px + env(safe-area-inset-bottom));
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  position: relative;
}

.card {
  position: relative;
}
.metroCard {
  position: relative;
}

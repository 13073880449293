$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  height: 100%;
  position: relative;
  display: flex;
  z-index: 0;
  flex-direction: column;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  // overflow-y: auto;

  .content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 48px;
    box-sizing: border-box;

    .header {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 48px 0px 48px;
      .avatar {
        width: 120px;
        height: 120px;
        margin-right: 24px;
        border-radius: 50%;
        border: 2px solid #fff;
        flex: none;
      }
      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .infoTop {
          display: flex;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 36px;
          color: #1d2129;
          line-height: 50px;
          .name {
            margin-right: 12px;
          }
        }
        .num {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 32px;
          color: #86909c;
          line-height: 45px;
          text-align: left;
        }
      }
      .seting {
        margin-left: 12px;
        margin-left: 12px;
        text-align: center;

        .setingIcon {
          flex: none;
          width: 36px;
          height: 36px;
          cursor: pointer;
          margin-bottom: 4px;
        }
        .setingText {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #85909d;
          line-height: 33px;
          text-align: left;
          font-style: normal;
        }
      }
    }

    .list {
      :global {
        --border-top: none;
        --border-bottom: none;
        .adm-list-item {
          --adm-color-background: transparent;
        }
        .adm-list-body {
          background-color: transparent;
        }
      }
      .listItem {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #1d2129;
        line-height: 40px;
        padding-left: 0px;
        &::after {
          display: none;
        }
        .left {
          display: flex;
          align-items: center;

          flex: 1;
          .leftIcon {
            width: 44px;
            height: 44px;
            margin-right: 24px;
          }
        }
        .right {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #85909d;
          line-height: 33px;
          text-align: center;
          font-style: normal;
          margin-right: 8px;
        }
        :global {
          .adm-list-item-content-main {
            padding: 38px 0;
            display: flex;
            align-items: center;
          }

          .adm-list-item-content {
            border-top: none;
            padding-right: 48px;
            padding-right: 0;
          }
        }
      }
    }
  }
}
.containerBack {
  background-color: #fff;
  height: 100%;
  width: 100vw;
  position: absolute;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    // top: calc(0px - var(--header-padding-top) - var(--header-title-height));
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 1;

    width: 100%;
    background: radial-gradient(circle, rgba(39, 130, 250, 0.7), transparent)
        130% -10% / 330px 330px no-repeat,
      radial-gradient(circle, rgba(173, 204, 244, 0.6), transparent) 0% 30% /
        770px 770px no-repeat,
      radial-gradient(circle, rgba(150, 157, 210, 0.2), transparent) 0% 50% /
        330px 330px no-repeat,
      radial-gradient(circle, rgba(194, 200, 247, 0.2), transparent) 100% 50% /
        770px 770px no-repeat,
      radial-gradient(circle, rgba(125, 166, 221, 0.2), transparent) 50% 90% /
        700px 700px no-repeat;
    filter: blur(100px);
  }
}
.icon {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #85909d;
  line-height: 33px;
  text-align: center;
  font-style: normal;
}
.listTips {
  margin-bottom: 24px;
  .tipsItem {
    display: flex;
    align-items: center;
    padding: 20px 24px;
    background-image: url("https://cdn.wuhuxianmai.cn/feed/1736330273997-a21e9c4d472df55a997979e01964057e0aaa8a7f355c3eae4861e6e275c686c1");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    // background: linear-gradient(90deg, #d5f1f8 0%, #edf8ff 51%, #eaecfe 100%);
    border-radius: 32px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .tipsImage {
      margin-right: 16px;
      width: 60px;
      height: 44px;
    }
    .tipsText {
      font-family: DOUYINSANSBOLD, DOUYINSANSBOLD;
      font-weight: bold;
      font-size: 32px;
      color: #0b3ae7;
      line-height: 39px;
      text-align: left;
      font-style: normal;
    }
  }
}
.walletList {
  --gap: 8px;
  .walletItem {
    text-align: center;
    .walletNum {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 36px;
      color: #1d2129;
      line-height: 50px;
      text-align: center;
      font-style: normal;
    }
    .walletText {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #4e5969;
      line-height: 33px;
      text-align: center;
      font-style: normal;
    }
  }
}
.orderList {
  --gap: 8px;
  .orderItem {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .orderImage {
      width: 76px;
      height: 76px;
      margin-bottom: 6px;
    }
    .orderText {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #4e5969;
      line-height: 33px;
      text-align: right;
      font-style: normal;
    }
  }
}
.moneyCard {
  background: linear-gradient(270deg, #2be0f3 0%, #1f84ff 100%);
  border-radius: 32px;
  padding: 26px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .moneyLeft {
    display: flex;
    align-items: center;
    .moneyIcon {
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
    .tip {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      line-height: 40px;
      text-align: left;
      font-style: normal;
    }
  }
  .action {
    background: #ffffff;
    border-radius: 34px;
    padding: 14px 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #06b1fa;
    line-height: 40px;
    text-align: left;
    font-style: normal;
  }
}
.tipAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  background: #fff9ed;
  padding: 24px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 30px;
  color: #ff6010;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  border-radius: 24px;
  .tipLeft {
    .tipLeftIcon {
      margin-right: 10px;
      font-weight: bold;
    }
  }
}
.serviceList {
  padding-top: 8px;
  --gap-vertical: 36px;
  .serviceItem {
    text-align: center;
    .serviceIcon {
      width: 44px;
      height: 44px;
    }
    .serviceText {
      margin-top: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #1d2129;
      line-height: 33px;
      font-style: normal;
    }
  }
}

.orderContent {
  max-height: 130px;
}
.orderShowContent {
  max-height: 3000px;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  width: 100%;
  height: 100vh;
  text-align: left;
  background-color: #fff;

  .innerContainer {
    padding: 0 32px;

    .tabs {
      padding-top: 24px;
      :global(.adm-tabs-header) {
        border: none;
      }

      :global(.adm-tabs-tab-active) {
        font-size: 32px;
        font-weight: 500;
      }
    }
    .swiper {

      .qrCodeBg {
        margin: 136px auto 0;
        width: 606px;
        height: 640px;
        background-image: url('https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/759037ad41d813bcdc4591f625a49543dceac455.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;

        .qrCode {
          width: 480px;
          height: 480px;
          margin: 0 auto;
          padding-top: 150px;
        }
      }
    }
    .partnerInfo {
      position: absolute;
      bottom: 17px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 24px;
      color: #86909C;
    }
  }
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  img {
    max-width: 100%;
    height: 100vh;
    cursor: pointer;
  }
}

body{background: #fff;}
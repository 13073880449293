$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
    width: 100%;
    height: 240px;
    background: transparent; 
    background-size: 100% 240px;
    background-repeat: no-repeat;
    margin: 0px;
  
    .header {
      display: flex;
      flex-flow: row;
      height: 90px;
      align-items:flex-end;
  
      .icon {
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 25px;
        display: inline-block;
        margin-left: 40px;
  
        img {
          width: 50px;
          height: 50px;
        }
      }
  
      .title{
          color: #fff;
          font-size: 28px;
          font-weight: bolder;
          margin-left: 10px;
      }
    }
  
    .content{
      color: #fff;
      font-size: 36px;
      font-weight: bolder;
      height: 150px;
      line-height: 150px;
      margin-left: 100px;
    }
  }
  
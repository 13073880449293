$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  background-color: #f5f5f5;
  color: #1d2129;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.customIndicator {
  position: absolute;
  bottom: 54px;
  right: 12px;
  background: rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  color: #fff;
  border-radius: 20px;
  user-select: none;
  font-variant-numeric: tabular-nums lining-nums;
}

.swiper {
  position: relative;
  flex: none;
  .image {
    width: 100%;
    // height: 300px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 32px 32px 0px 0px;
  //   background-color: #fff;
  background: #f2302f;
  margin-top: -32px;
  z-index: 1;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2302f;
    border-radius: 21px 21px 0px 0px;
    height: 70px;
    padding-left: 28px;
    overflow: hidden;
    padding-bottom: 36px;
    padding-top: 16px;
    .headerLeft {
      display: flex;
      align-items: center;
      .discount {
        width: 48px;
        margin-right: 20px;
      }
      .title {
        font-family: AlibabaPuHuiTi_2_85_Bold;
        font-size: 36px;
        color: #fffdfc;
        line-height: 50px;
        text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.18);
        text-align: left;
        font-style: normal;
        font-weight: bold;
        margin-right: 28px;
      }
      .discountValue {
        display: flex;
        align-items: center;
        padding: 4px 48px;
        background: #ffffff;
        box-shadow: 2px 2px 0px 0px #e30000;
        border-radius: 8px;
        font-weight: bold;
        .discountValueNumber {
          // font-family: AlibabaPuHuiTi_2_95_ExtraBold;
          font-size: 64px;
          color: #f20000;
          line-height: 64px;
          text-align: left;
          font-style: normal;
          margin-right: 8px;
        }
        .discountValueUnit {
          // font-family: AlibabaPuHuiTi_2_85_Bold;
          font-size: 36px;
          color: #f20000;
          line-height: 50px;
          text-align: left;
          font-style: normal;
        }
      }
    }
    .tipImg {
      position: relative;
      right: -40px;
      bottom: -40px;
      //   width: 76px;
      height: 114px;
    }
  }
  .detail {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    flex: 1;
    background-color: #fff;
    border-radius: 21px 21px 0px 0px;
    margin-top: -20px;
    padding: 58px 40px 0;
    text-align: left;
    .titleInfo {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .restaurantLogo {
        width: 102px;
        height: 102px;
        border-radius: 10px;
        margin-right: 24px;
        flex: none;
      }
      .restaurantInfo {
        // display: flex;
        .restaurantName {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 40px;
          color: #1d2129;
          line-height: 56px;
          text-align: left;
          font-style: normal;
          margin-bottom: 12px;
          // 多行省略
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .time {
          display: flex;
          align-items: center;
          .timeTip {
            margin-right: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #ff5500;
            line-height: 33px;
            text-align: left;
            font-style: normal;
          }
          .disabledTip {
            color: #86909c;
          }
          .timeText {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 24px;
            color: #86909c;
            line-height: 33px;
            text-align: left;
            font-style: normal;
          }
        }
      }
    }
    .aliPayTip {
      padding: 4px 12px;
      background: #fbf0e4;
      border-radius: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #b16616;
      line-height: 33px;
      text-align: left;
      font-style: normal;
    }
    .addressModel {
      position: relative;
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      padding: 28px 24px;
      margin-top: 24px;
      background: #f7f8f9;
      border-radius: 24px;
      //   background-image: url("https://wanmi-ares.oss-cn-hangzhou.aliyuncs.com/upload/%E5%9C%B0%E5%9B%BE-16324ac5-1c3e-4f3d-aae3-c4c32654fbda.png");
      //   background-repeat: no-repeat;
      //   background-size: auto 100%;
      //   background-position: right center;
      //   background-clip: ;
      // 原有背景图设置转移到伪元素
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("https://wanmi-ares.oss-cn-hangzhou.aliyuncs.com/upload/%E5%95%86%E5%AE%B6%E8%AF%A6%E6%83%85%E5%9C%B0%E5%9D%80-4512e3e8-cac5-4120-b5e9-dc1492f952a4.png");
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: right center;
        // filter: blur(10px); // 模糊强度
        z-index: 0; // 确保在内容下方
        // opacity: 0.4;

        // 可选：增加亮度/对比度调节
        // filter: blur(10px) brightness(0.8);
      }

      .addressInfo {
        z-index: 1;
        margin-right: 10px;
        // display: flex;
        // align-items: center;
        .address {
          display: flex;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 28px;
          color: #1d2129;
          line-height: 40px;
          text-align: left;
          font-style: normal;
          margin-bottom: 4px;
          .addressText {
            // 1行省略
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .addressRightIcon {
            width: 30px;
            height: 30px;
            flex: none;
          }
        }
        .addressDetail {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #86909c;
          line-height: 33px;
          text-align: left;
          font-style: normal;
          .addressIcon {
            width: 24px;
            height: 24px;
            color: #86909c;
            margin-right: 6px;
          }
        }
      }
      .addressBtn {
        z-index: 1;
        display: flex;
        align-items: center;
        .action {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-right: 32px;
          &:last-child {
            margin-right: 0;
          }
          .actionIcon {
            width: 48px;
            height: 48px;
            margin-bottom: 4px;
          }
          .actionText {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 20px;
            color: #1d2129;
            line-height: 28px;
            text-align: left;
            font-style: normal;
          }
        }
      }
    }
    .recommend {
      margin-top: 48px;
      flex: 1;
      .recommendTitle {
        position: relative;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #1d2129;
        line-height: 42px;
        text-align: left;
        font-style: normal;
        padding-left: 16px;
        &::before {
          position: absolute;
          display: inline-block;
          content: "";
          width: 4px;
          height: 24px;
          left: 0;
          top: calc(50% - 12px);
          background: #fe5100;
          border-radius: 4px;
        }
      }
      .recommendShopList {
        margin-top: 32px;
        .recommendShop {
          display: flex;
          margin-bottom: 66px;
          //   &:last-child {
          //     margin-bottom: 0;
          //   }
          .recommendShopImg {
            flex: none;
            border-radius: 8px;
            overflow: hidden;
            margin-right: 16px;
            .recommendShopImgTip {
              position: absolute;
              bottom: 0;
              left: 0;
              background: #fe5100;
              border-radius: 0px 8px 0px 8px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 24px;
              color: #ffffff;
              line-height: 33px;
              text-align: left;
              font-style: normal;
              padding: 4px 12px;
            }
            .recommendShopImage {
              width: 200px;
            }
          }
          .recommendShopInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            .recommendShopInfoContent {
              .recommendShopName {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 28px;
                color: #1d2129;
                line-height: 40px;
                text-align: left;
                font-style: normal;
                // 1行省略
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .recommendShopAddress {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 24px;
                color: #86909c;
                line-height: 33px;
                text-align: left;
                font-style: normal;
                // 1行省略
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .recommendShopTime {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 24px;
                color: #86909c;
                line-height: 33px;
                text-align: left;
                font-style: normal;
                // 1行省略
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
            .recommendShopPhone {
              width: 48px;
              height: 48px;
              flex: none;
            }
          }
        }
      }
    }
  }
}
.button {
  position: sticky;
  bottom: env(safe-area-inset-bottom);
  background: #f2302f;
  //   margin: 0 40px;
  border-radius: 16px;
  border-color: #f2302f;
  width: 100%;
  box-sizing: border-box;
}
.popup {
  text-align: center;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  margin: 0 auto;
  text-align: left;

  .swiper {
    position: relative;

    .customIndicator {
      position: absolute;
      top: 12px;
      right: 12px;
      background: rgba(0, 0, 0, 0.6);
      padding: 4px 8px;
      color: #fff;
      border-radius: 20px;
      user-select: none;
      font-variant-numeric: tabular-nums lining-nums;
    }
  }

  .icon {
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 0px;
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      border-radius: 50%;
    }

    span {
      font-weight: bolder;
      font-size: 36px;
      display: inline-block;
      margin-left: 4px;
    }
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    margin: 20px;
  }

  .content {
    padding: 0 20px;

    .pre {
      font-size: 28px;
      white-space: pre-wrap;
    }
  }
}

body {
  background: #f4f9ff;
}
.safeArea {
  background-color: #f4f9ff;
}

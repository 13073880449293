$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.body {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  overflow: hidden;
  height: 95vh;
  .container {
    height: 100%;
    position: relative;
    padding: 0 24px;
    box-sizing: border-box;
    overflow-y: auto;
    .header {
      position: sticky;
      top: 0;
      padding-top: 24px;
      z-index: 1;
      background: #fff;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 20px;
    }
    .map {
      height: auto;
    }
  }
}
.fontSize28 {
  --font-size: 28px;
}

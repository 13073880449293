$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
:local(.app) {
  width: 100%;
  text-align: left;
  margin-top: 30px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 24px;
  overflow: hidden;

  :global {
    .adm-list-item-content-prefix {
      padding-right: 0px;
      width: 170px;
    }

    .adm-form-item-label {
      font-size: 28px;
      color: #4E5969;
    }

    .adm-input-element{
        font-size: 28px;
    }
  }
}

.ml20 {
  margin-left: 20px;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.toTop {
  display: flex;
  // opacity: 0;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 24px;
  bottom: 50px;
  width: 88px;
  height: 88px;

  background: #fefefe;
  border: 2px solid #ebebeb;
  border-radius: 50%;
  font-weight: 400;
  font-size: 24px;
  color: #1d2129;
  line-height: 33px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transform: scale(0);
  z-index: 1000;
  .up {
    width: 36px;
    height: 36px;
  }
}
.active {
  transform: scale(1);
}
.safeArea {
  bottom: calc(50px + env(safe-area-inset-bottom));
}

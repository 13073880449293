$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
@import "@/styles/_mixins.scss";
.enterprise {
  @include background-image(
    "%E4%BD%8D%E5%9B%BE11-f2e0e4e3-0ebc-48c2-bc25-213d3134cfa0.png"
  );
  padding: 0 40px;
  .container {
    margin-top: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    padding: 26px 40px;
    margin: 0 -40px 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    .logoContainer {
      display: flex;
      align-items: center;
      .logo {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }
      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 28px;
        color: #d8efff;
        line-height: 40px;
        text-align: left;
        font-style: normal;
      }
    }
    .settingButton {
      display: flex;
      align-items: center;
      justify-content: center;
      .setting {
        color: #fff;
        width: 32px;
        height: 32px;
      }
      .settingText {
        margin-left: 4px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #cfd4d8;
        line-height: 33px;
        text-align: left;
        font-style: normal;
      }
    }
  }
  .space {
    // width: 100%;
    --gap: 16px;
  }
  .income {
    display: flex;
    width: 100%;
    align-items: center;
    .incomeItem {
      flex: 1;
      width: calc(50% - 12px);
      .incomeNum {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #1d2129;
        line-height: 50px;
        text-align: center;
        font-style: normal;
      }
      .incomeText {
        margin-top: 8px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #4e5969;
        line-height: 33px;
        text-align: center;
        font-style: normal;
      }
    }
    .divider {
      flex: none;
      margin: 0 10px;
      height: 32px;
      border-left: 4px solid #f2f3f5;
    }
  }
  .performanceItem {
    .performanceNum {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 36px;
      color: #1d2129;
      line-height: 50px;
      text-align: center;
      font-style: normal;
    }
    .performanceText {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #4e5969;
      line-height: 33px;
      text-align: center;
      font-style: normal;
    }
  }
  .serviceItem {
    text-align: center;
    padding-top: 8px;
    &:active {
      background-color: #f5f7fa;
    }

    .serviceItemIcon {
      width: 44px;
      height: 44px;
      margin-bottom: 12px;
    }

    .serviceItemText {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #1d2129;
      line-height: 33px;
      font-style: normal;
    }
  }
}
.service {
  --gap: 33px;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  height: 800px;
  margin: 0px;
  padding: 0px;
  text-align: center;

  li {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  .title {
    color: #131415;
    font-size: 36px;
    padding-top: 80px;
    font-weight: bolder;
  }

  .amount {
    color: #86909c;
    font-size: 24px;
    margin-top: 10px;
    span {
      color: #1d2129;
      margin-left: 6px;
    }
  }

  .passcode {
    margin-top: 80px;
    --cell-size: 96px;
    --border-color: transparent;
    --cell-gap: 20px;
    --border-radius: 16px;
    --adm-color-background: #f3f5f7;
  }
}

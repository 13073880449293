$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 700px;
  margin: 0 auto;
  text-align: left;
  padding-top: 50px;
  box-sizing: border-box;

  .card {
    width: 100%;
    height: 380px;
    background: #fff;
    margin-bottom: 50px;
    border-radius: 25px;
    overflow: hidden;

    .buttons {
      height: 140px;
      width: 100%;
      background: #fff;
      margin-top: -15px;
      display: flex;
      align-items: center;
    }

    .footer {
      width: 575px;
      height: 80px;
      margin: 0 auto;
      border: solid 2px #e5e6eb;
      border-radius: 48px;
      display: flex;
      flex-flow: row;
      color: #1d2129;
      font-size: 28px;
      line-height: 80px;
      justify-content: center;

      span {
        display: inline-block;
        cursor: pointer;
      }
    }

    .divider {
      height: 60px;
      margin-top: 15px;
    }
  }
}

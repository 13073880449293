$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.infoCard {
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  .image {
    width: 100%;
    height: 454px;
  }
  .content {
    padding: 24px 16px 16px;
    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 26px;
      color: #333333;
      line-height: 37px;
      text-align: left;
      margin-bottom: 22px;
    }
    .footer {
      display: flex;
      align-items: center;
      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .name {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #86909c;
        line-height: 33px;
      }
    }
  }
}

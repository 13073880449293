$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.contentCard {
  position: relative;
  background-color: #fff;
  // background: rgba(255, 255, 255, 0.65);
  border-radius: 24px;

  padding: 32px 32px;
  box-sizing: border-box;
  backdrop-filter: blur(24px);
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .cardTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #1d2129;
    line-height: 45px;
    text-align: left;
    font-style: normal;
    .extra {
      display: flex;
      align-items: center;

      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #85909d;
      line-height: 33px;
      text-align: center;
      font-style: normal;
      .icon {
        margin-left: 8px;
      }
    }
  }
  .content {
    padding-top: 24px;
    overflow: hidden;
    // max-height: 130px; // 改用max-height
    transition: max-height 0.5s ease-in-out; // 过渡属性需要在这里声明
  }
  .showAll {
    // max-height: 1000px; // 设置足够大的最大高度值
  }
}
.action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
  .actionText {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #4e5969;
    line-height: 28px;
    text-align: left;
    font-style: normal;
  }
  .actionIcon {
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
  }
  .actionIconRotate {
    transform: rotate(90deg);
  }
}

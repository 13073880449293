$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  height: 240px;
  background: transparent;
  background-size: 100% 240px;
  background-repeat: no-repeat;
  margin: 15px 0px;
  background: linear-gradient(135deg, #33afff 0%, #1777ff 100%);
  border-radius: 24px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background-image: url("https://cdn.wuhuxianmai.cn/feed/1732862070542-2d85ea416ddbcd5e8fb2072254392561b5d1e6155fb8241c61312abe4991fee4");
  background-size: 100% auto;
  background-repeat: no-repeat;
  .header {
    display: flex;
    flex-flow: row;
    height: 90px;
    align-items: flex-end;

    .icon {
      width: 50px;
      height: 50px;
      background: #fff;
      border-radius: 25px;
      display: inline-block;
      margin-left: 40px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .title {
      color: #fff;
      font-size: 28px;
      font-weight: bolder;
      margin-left: 10px;
      display: inline-block;
      line-height: 50px;
    }
  }

  .content {
    color: #fff;
    font-size: 36px;
    font-weight: bolder;
    height: 150px;
    line-height: 150px;
    margin-left: 100px;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  height: 100vh;
  text-align: left;
  background: #fff6db;

  .banner {
    width: 100%;
    height: 480px;
    background-image: url("https://cdn.wuhuxianmai.cn/images/b2412d4f0c5d3e85.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .content {
    width: 700px;
    min-height: 200px;
    margin: 0 auto;

    img {
      width: 100%;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}


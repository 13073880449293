$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
@import "@/styles/_mixins.scss";
.page {
  @include background-image(
    "%E4%BD%8D%E5%9B%BE11-f2e0e4e3-0ebc-48c2-bc25-213d3134cfa0.png"
  );
}
.form {
  padding: 0;
  :global {
    .adm-list-card {
      margin: 0 0 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.footer {
  width: 100%;
}

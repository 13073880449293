$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  border-radius: 16px;
  background: #fff;
  margin-top: 20px;
  overflow: hidden;
  color: #1d2129;
  font-size: 28px;

  .detail {
    font-size: 24px;
    color: #1d2129;
    background-color: rgba(224, 237, 255, 0.35);
    --padding-left: 76px;

    :global(.adm-list-item-content-main) {
      padding: 20px 0;
    }

    .list {
      margin-top: 4px;
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      .question {
        position: relative;
        top: 2px;
      }
    }
  }

  .add {
    padding-left: 76px;
    font-size: 28px;
  }


  .item {
    --padding-left: 76px;

    .checkbox {
      &:global(.adm-checkbox-checked) {
        :global(.adm-checkbox-icon) {
          background-color: #F2302F !important;
          border-color: #F2302F !important;
        }
      }
    }
  }
}

.detalTitle {
  color: #1d2129;
  font-size: 28px;
}

.detalText {
  color: #86909C;
  font-size: 24px;
}

.detalValue {
  color: #1d2129;
  font-size: 24px;
}

.detail {
  background: #E0EDFF;
  font-size: 24px;
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  background-color: rgb(245, 245, 245);
}

.searchContainer {
  padding: 24px;
  background-color: #fff;
}

.employeeListContainer {
  padding: 0 24px 24px;
  background-color: rgb(245, 245, 245);

  .indexBar {
    background: none;
  }
}

.avatarByName {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: #1F86FF;
  color: #fff;
  font-size: 28px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
}

.employeeList {
  background-color: rgb(245, 245, 245);

  :global(.adm-list-body) {
    border-radius: 16px;
    border-top: none;
    border-bottom: none;
  }
}

.dialogGrid {
  padding-top: 40px;
}

.continueBtn {
  width: 230px;
  height: 88px;
  background: #FFFFFF;
  border-radius: 46px;
  border: 2px solid #E5E6EB;
  font-weight: 500;
  font-size: 28px;
  color: #1D2129;
  line-height: 40px;
}

.backBtn {
  width: 230px;
  height: 88px;
  background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
  border-radius: 46px;
  font-weight: 500;
  font-size: 28px;
  color: #FFFFFF;
  line-height: 40px;
}

.floatButton {
  position: fixed;
  right: 24px;
  bottom: 92px;
  z-index: 1000;

  .floatButtonImage {
    width: 96px;
    height: 96px;
  }

  &:active {
    opacity: 0.8;
  }
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  margin: 0 auto;
  min-height: 100vh;

  .header {
    text-align: center;
    padding-top: 238px;
    
    .imageWrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    margin-top: 48px;
  }
}

.content {
  border-radius: 24px;
  background: #fff;
  overflow: hidden;
  margin: 32px 0 0;

  .header {
    font-family: PingFangSC, PingFang SC;
    color: #131415;
    font-size: 32px;
    font-weight: 600;
    padding: 50px 0px 41px 0px;
    border-bottom: dashed 2px #f2f3f5;
    margin:0 40px 41px;

    .amount {
      font-size: 56px;
      margin-left: 8px;
    }
  }

  .list {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: 28px;
    box-sizing: border-box;
    padding: 32px 32px;

    .label {
      color: #86909c;
    }
    .value {
      color: #1d2129;
    }
  }
}

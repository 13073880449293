$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.outTabs {
  --active-line-height: 8px;
  --active-line-border-radius: 4px;
  --active-title-color: #242931;
  --fixed-active-line-width: 40px;
  --title-font-size: 28px;
  --content-padding: 0;
  text-align: left;

  :global {
    .adm-tabs-header {
      border: none;
      position: sticky;
      // top: 0;
      // z-index: 1000;
      // height: 88px;
    }
    .adm-tabs-tab {
      padding: 30px 0 14px 0px;
    }
    .adm-tabs-tab-wrapper {
      padding: 0 24px;
      flex: none;
    }
    .adm-tabs-tab-list {
      background-color: #fff;
    }
    .adm-tabs-tab-line {
      bottom: 5px;
    }
  }
  .activeTab {
    font-weight: 500;
    font-size: 32px;
    color: #242931;
    line-height: 45px;
  }
  .tabsItem {
  }
  .middleAction {
    margin-top: 16px;
  }
}
.inTabs {
  --active-line-height: 0px;
  //   --active-line-border-radius: 4px;
  --active-title-color: #f3f3f3;
  --fixed-active-line-width: 0px;
  --title-font-size: 24px;
  --content-padding: 24px;
  text-align: left;

  :global {
    .adm-tabs-header {
      border: none;
      // position: sticky;
      // top: 87px;
      will-change: transform; /* 启用硬件加速 */
      backface-visibility: hidden; /* 启用硬件加速 */
    }
    .adm-tabs-tab {
      padding: 16px 0 16px 0px;
    }
    .adm-tabs-tab-wrapper {
      padding: 0 6px;
      flex: none;
    }
    .adm-tabs-tab-list {
      padding: 0 18px;
    }
    .adm-tabs-content {
      background-color: #f5f5f5;
    }
    .adm-tabs-header-mask {
      width: 24px;
    }
  }
  .activeTab {
    font-weight: 400;
    font-size: 24px;
    color: #f3f3f3;
    line-height: 33px;
    background-color: var(--adm-color-primary);
    border-radius: 8px;
    padding: 7px 24px;
  }
  .noActiveIn {
    background-color: #f5f5f5;
    font-weight: 400;
    font-size: 24px;
    color: #86909c;
    line-height: 33px;
    padding: 7px 24px;
    border-radius: 8px;
  }
  .tabsItem {
  }
  .middleAction {
    margin-top: 16px;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.header {
  font-size: 32px;
  color: #1d2129;
  height: 45px;
  line-height: 45px;
  font-weight: bolder;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;

  .num {
    color: #86909c;
    font-size: 26px;
    font-weight: normal;
  }

  .button {
    color: #4e5969;
    font-size: 24px;
    cursor: pointer;
  }
}

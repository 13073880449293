$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.pageItem {
  background-color: transparent;
}
.loading {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  padding: 0 24px;
  background-color: #F4F9FF;
  min-height: 100vh;
  margin: 0 auto;
  text-align: center;

  .title {
    margin-top: 40px;
    font-weight: 500;
    font-size: 48px;
    color: #1D2129;
  }

  .tips {
    color: #86909C;
    margin-top: 15px;
  }

  .phone {
    margin-top: 82px;
    font-weight: 500;
    font-size: 48px;
    color: #1D2129;
  }

  .actionContainer {
    margin: 79px 22px 0;
  }

  .submitVerifyButton {
    background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
    border-radius: 46px;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    height: 88px;
    border: none;

    &:active {
      opacity: 0.5;
    }

  }
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #E8EFFD;
  background-image: url('https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/bg-open-arrive-store.png');
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;
}

.operation {
  position: fixed;
  bottom: 30px;
  left: 0;
  width: 750px;
  height: 125px;
  display: flex;
  background-image: url('https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/jw1kbbgdxf.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.call {
  width: 160px;
  height: 100%;
}

.open {
  width: 548px;
  height: 100%;
  line-height: 125px;
  font-size: 30px;
  color: #fff;
  text-align: center;
}

.roleModal {
  width: 580px;
}

.role {
  box-sizing: border-box;
  height: 160px;
  padding: 38px 32px;
  margin-bottom: 24px;
  border-radius: 32px;
  display: flex;
  align-items: center;
}

.staff {
  background-color: #E8F2FF;
}

.admin {
  background-color: #EFEFFE;
}

.citizen {
  background-color: #E7F9F8;
}

.roleAvatar {
  width: 84px;
  height: 84px;
  margin-right: 24px;
}

.roleInfo {
  flex: 1;
  text-align: left;
}

.roleName {
  margin-top: 4px;
  font-weight: 500;
  font-size: 30px;
  color: #1D2129;
}

.roleDesc {
  font-weight: 400;
  font-size: 24px;
  color: #86909C;
}

.iconContainer {
  width: 28px;
  text-align: center;
}

.authPopup {
  padding: 48px;
}

.appTitle {
  display: flex;
  align-items: center;
}

.appLogo {
  width: 48px;
  height: 48px;
}

.appName {
  margin-left: 12px;
  font-weight: 500;
  font-size: 28px;
  color: #1D2129;
}

.authTips {
  margin-top: 32px;
}

.authTipsTitle {
  font-weight: 500;
  font-size: 36px;
  color: #1D2129;
}

.authTipsDesc {
  margin-top: 12px;
  font-weight: 400;
  font-size: 28px;
  color: #4E5969;;
}

.authForm {
  margin: 64px 0;
}

.authPopupButtons {
  display: flex;
  justify-content: space-between;
}

.authPopupButton {
  width: 48%;
  border-color: #E5E6EB;
}

.authPopupButtonPrimary {
  width: 48%;
  border: 0 none;
  background: linear-gradient(269deg, #33AFFF 0%, #1777FF 100%);
}

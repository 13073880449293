$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.content {
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  text-align: center;
  .icon {
    width: 164px;
    height: 164px;
  }
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #1d2129;
    line-height: 45px;
    margin-bottom: 15px;
    margin-top: 24px;
  }
  .subTitle {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 28px;
    color: #86909c;
    line-height: 40px;
    margin-bottom: 64px;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  background-color: #fff;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}
.form {
  text-align: left;
  flex: 1;
  width: 100%;
  :global {
    .adm-list-body {
      border-top: none;
      .adm-list-item:first-child .adm-list-item-content {
        border-top: none;
      }
      .adm-list-item-content .adm-form-item-label {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 30px;
        color: #1d2129;
        line-height: 42px;
      }
    }
  }
  .picker {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 30px;
    color: #1d2129;
    line-height: 42px;
  }
  .input {
    & > input {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 30px;
      color: #1d2129;
      line-height: 42px;
      &::placeholder {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 30px;
        color: #86909c;
        line-height: 42px;
      }
    }
  }
}
.footer {
  width: 100%;
  position: sticky;
  bottom: calc(env(safe-area-inset-bottom));
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  text-align: center;
  margin: 25px 0px 40px 0px;

  .button {
    font-size: 28px;
  }
  .icon {
    font-weight: bolder;
  }
}

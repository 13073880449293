$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  margin: 24px;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;

  .result {
    margin: 40px 40px 0 40px;
    padding: 0;
    border-bottom: 2px solid #F2F3F5;

    .price {
      font-size: 48px;
      color: #1d2129;
      font-weight: 600;
      font-family: PingFangSC, PingFang SC;
      padding-bottom: 60px;
    }
  }

  .resultContent {
    padding: 40px 0;

    .list {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      font-size: 28px;
      box-sizing: border-box;
      align-items: center;
      margin: 0 40px;
      padding: 15px 0;
      .label {
        color: #86909c;
      }
      .value {
        display: flex;
        align-items: center;
        color: #1d2129;
      }
      .rightIcon {
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
    }
    .refund {
      padding:7px 0 47px 0;
      border-bottom: 2px solid #F2F3F5;
      margin-bottom: 30px;
      .value {
        color: #F4413A;
        .rightIcon {
          color: #86909c;
        }
      }
    }
  }

  .discount {
    .label,
    .value {
      color: #FE5100 !important;
    }
  }
}


$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.form {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 32px;
  
    :global {
      .adm-list-card {
        margin: 0 0 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }
  .footer {
    width: 100%;
    position: sticky;
    bottom: env(safe-area-inset-bottom);
  }

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.nav {
  width: 750px;
  height: 90px;
  margin: 0 auto;
  background: #1777ff;
  color: #fff;
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  .icon {
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      margin-left: 10px;
    }

    span {
      font-weight: bolder;
      font-size: 36px;
      display: inline-block;
      margin-left: 4px;
    }
  }

  .my {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    span {
      margin-right: 20px;
      display: inline-block;
      font-weight: bolder;
      font-size: 28px;
    }

    img {
      width: 50px;
      margin-right: 20px;
    }

    .coupon{
        margin-top: 8px;
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.toolbar {
  background: #fff;
  width: 700px;
  height: 360px;
  border-radius: 20px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  color: #1d2129;
  background-image: url("https://cdn.wuhuxianmai.cn/images/df35543c611dcea0.png");
  background-size: 700px 360px;
  background-repeat: no-repeat;

  .item {
    width: 135px;
    display: inline-block;
    height: 165px;
    margin-right:1px;
    cursor: pointer;
  }

  .toolbar-item {
    display: inline-block;
    width: 136px;
    height: 170px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 10px;

    i {
      font-size: 80px;
    }

    .text {
      font-size: 25px;
      margin-top: 10px;
      color: #1d2129;
    }

    .more {
      color: #bbb;
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  min-height: 100vh;
  background-color: #F4F9FF;
  display: flex;
  flex-direction: column;
}

.protocolContainer {
  flex: 1;
  background-color: #fff;
  box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.03);
border-radius: 16px;
  margin: 24px;
  display: flex;
  flex-direction: column;

  .protocolTitle {
    padding: 24px;
    font-size: 36px;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin: 0;
  }

  .protocolContent {
    flex: 1;
    padding: 0 24px 24px;
    overflow-y: auto;
    line-height: 1.8;
    white-space: pre-wrap;
    color: #333;
    font-size: 28px;

    p {
      margin: 18px 0;
    }
  }
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  height: 100%;
  // background: linear-gradient(to top, #187aff, #35b2ff);
  background: linear-gradient(322deg, #35b2ff 0%, #187aff 100%);
  background-image: url("https://cdn.wuhuxianmai.cn/images/89666ba338677c847e20ed4387cacc9f3bd843b1.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  .container {
    // width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;

    .header {
      color: #fff;
      padding-top: 48px;
      width: 100%;
      padding-left: 48px;
      width: 100%;
      box-sizing: border-box;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      line-height: 33px;
      text-align: left;
      font-style: normal;
      li:first-child {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 56px;
        color: #ffffff;
        line-height: 78px;
        text-align: left;
        font-style: normal;
      }
    }

    .swiper {
      padding-left: calc(50% - 325px);
      box-sizing: border-box;
      padding-bottom: 80px;
      position: relative;
      :global {
        .adm-swiper-track-inner {
          width: 670px;
        }
      }
      .customIndicator {
        width: 100%;
        margin-left: calc(325px - 50%);

        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        .customIndicatorItem {
          text-align: center;
          height: 16px;
          width: 16px;
          margin: 0 16px;
          background-color: #fff;
          border-radius: 50%;
          opacity: 0.5;
        }
        .customIndicatorItem.active {
          opacity: 1;
        }
      }
    }
    .oneSwiper {
      :global {
        .adm-swiper-track-inner {
          width: 650px;
          transform: translate3d(0, 0, 0) !important;
        }
      }
    }
  }
}

.card {
  padding: 0 40px;
  margin-top: 80px;
  height: 874px;
  width: 650px;
  box-sizing: border-box;
  border-radius: 24px;

  .cardHeader {
    align-items: center;
    justify-content: center;
    padding: 32px;
    :global {
      .adm-card-header-title {
        font-family: AlibabaPuHuiTi_2_85_Bold;
        font-size: 32px;
        color: #131415;
        line-height: 45px;
        text-align: left;
        font-style: normal;
      }
    }
  }
  .cardBody {
    text-align: center;
    padding: 80px 0;
    .image {
      margin: 0 auto;
      width: 482px;
      height: auto;
    }
    .tips {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 90px;
      margin-top: 64px;

      .tipsItem {
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        .tipsItemIcon {
          width: 48px;
          height: 48px;
          margin-bottom: 6px;
        }
        .tipsItemText {
          font-family: AlibabaPuHuiTi_2_55_Regular;
          font-size: 24px;
          color: #131415;
          line-height: 33px;
        }
      }
    }
    .button {
      padding: 24px 0;
      line-height: 40px;
    }
  }
}

.dialogContainer {
  :global(.adm-modal-title) {
    margin-top: 24px;
  }
  .dialogContent {
    .dialogTitle {
      font-size: 28px;
      color: #86909C;
      text-align: center;
      margin-bottom: 48px;
    }
    .continueBtn {
      width: 100%;
      height: 88px;
      background: #FFFFFF;
      border-radius: 46px;
      border: 2px solid #E5E6EB;
      font-weight: 500;
      font-size: 28px;
      color: #1D2129;
      line-height: 40px;
    }
    
    .backBtn {
      width: 100%;
      height: 88px;
      background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
      border-radius: 46px;
      font-weight: 500;
      font-size: 28px;
      color: #FFFFFF;
      line-height: 40px;
    }
  }
}




$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.cardContent {
  --gap: 32px;
  .container {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;
    .img {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      margin-right: 16px;
    }
    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 32px;
      color: #1d2129;
      line-height: 45px;
      text-align: left;
      font-style: normal;
    }
  }
  .message {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #86909c;
    line-height: 33px;
    text-align: left;
    font-style: normal;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  background: linear-gradient(322deg, #35b2ff 0%, #187aff 100%);
  background-image: url("https://cdn.wuhuxianmai.cn/feed/1732593249894-0a79226f84aad1c444cdedb798d0c9d92d67ffd4b8fc6315dc90402795208108");
  background-size: 100% auto;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  display: flex;
  // padding: 48px;
  .image {
    margin: 48px auto 0;
    width: 716px;
    height: auto;
  }
  .card {
    padding: 0 40px;
    width: calc(100% - 96px);
    box-sizing: border-box;
    margin-top: -136px;
    .cardBody {
      text-align: center;
      padding: 64px 0;
      .button {
        background: #1777ff;
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.orderSubject {
  font-size: 28px;
  color: #1D2129;
  font-weight: 500;
}

.orderRightOutline {
  font-weight: 800;
}

.avatarByName {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: #1F86FF;
  color: #fff;
  font-size: 28px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
}

.orderList {
  background-color: rgb(245, 245, 245);
  text-align: left;
  margin: 24px 24px;
  
  :global(.adm-list-body) {
    border-radius: 16px;
  }

  :global(.adm-list-item-content-extra) {
    font-size: 28px;
    color: #1D2129;
    font-weight: 500;
    display: flex;
    align-self: flex-start;
    padding-top: 30px;
  }
}

.orderDiscount {
  font-size: 24px;
  line-height: 24px;
  color: #131415;
}

.noInvoice {
  font-size: 24px;
  color: #86909C;
}

.refundStatus {
  font-size: 28px;
  color: #F2302F;
  font-weight: 400;
}

.orderExtraItem {
  :global(.adm-list-item-content) {
    padding:0;
    margin-right: 24px;
    border-color: #F2F3F5;
  }
}
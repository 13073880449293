$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.layout {
  text-align: left;
  background: #f4f9ff;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  scroll-behavior: smooth;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 24px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-image: url("https://cdn.wuhuxianmai.cn/feed/1732070781431-999edb2a6c14f6668805792c36219293fda961c85f9bd265fb142e44d3a277a9");
  
  .content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    margin-top: 32px;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 750px;
  background: #fff;
  height: 120px;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-top: 20px;
  color: #1d2129;
  font-size: 28px;
  margin: 0 auto;

  .node {
    width: 33%;
    display: inline-block;
    line-height: 45px;
  }

  .divider {
    height: 100px;
    border: solid 1px #e5e6eb;
    border-width: 0px 1px;
  }
}

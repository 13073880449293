$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.header {
  padding-top: 30px !important;
  box-sizing: border-box;
  font-family: PingFangSC, PingFang SC;
  

  .sub {
    color: #86909c;
    font-size: 24px;
  }

  .amount {
    padding-top: 10px;
    color: #F2302F;
    font-size: 44px;
    font-weight: 500;

    .symbol {
      font-size: 44px;
      font-weight: 700;
      margin-right: 2px;
    }

    span {
      font-size: 80px;
      font-weight: bolder;
    }
  }
  .time {
    margin: 6px 0 30px 0;
    color: #86909c;
    font-size: 24px;
    font-variant-numeric: tabular-nums lining-nums;
  }
  .count {
    font-variant-numeric: tabular-nums lining-nums;
  }
}



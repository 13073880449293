$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
@import "@/styles/_mixins.scss";

.page {
  @include background-image(
    "%E4%BD%8D%E5%9B%BE11-f2e0e4e3-0ebc-48c2-bc25-213d3134cfa0.png"
  );
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 50px;
      margin-top: 48px;
      .left,
      .right {
        // flex: 1;
        width: calc(50% - 2px);
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 48px;
          color: #ffffff;
          line-height: 67px;
          text-align: center;
          font-style: normal;
        }
        .text {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #ffffff;
          line-height: 33px;
          text-align: center;
          font-style: normal;
        }
      }
      .left {
        .title {
          color: #fce4b8;
        }
      }
      .middle {
        width: 4px;
        height: 32px;
        background: #ffffff;
        opacity: 0.5;
      }
    }
    .table {
      --col-gap: 50px;
      width: 100%;
      flex: 1;
      .tableBody {
        max-height: calc(100vh - 350px);
        overflow: auto;
      }
    }
  }
}
.failStatus {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 28px;
  color: #f47461;
  line-height: 40px;
  text-align: left;
  font-style: normal;
}
.successStatus {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 28px;
  color: #37be71;
  line-height: 40px;
  text-align: left;
  font-style: normal;
}
.incomeNum {
  width: 100%;
  text-align: right;
}

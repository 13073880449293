$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.card {
  background: #fefffe;
  border-radius: 16px;
  .header {
    // background-color: #f5f5f5;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 24px;
    background-size: 718px 128px;
    height: 128px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 32px;
      color: #242931;
      line-height: 45px;
      text-align: left;
    }
    .subTitle {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #242931;
      line-height: 33px;
      text-align: left;
    }
  }
  .content {
    // overflow-x: auto;

    padding: 16px 0px 0px;
    margin: 0 16px;
    text-align: left;
    .customIndicator {
      // background-color: #fe3664;

      --active-dot-color: #fe3664;
    }
    .paragraph {
      height: 50vh;
    }
    .swiper {
      padding-bottom: 24px;
    }
    .grid {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      :global {
        --gap-horizontal: 16px;
      }
      .gridItem {
        .productCard {
          width: 218px;
          border-radius: 8px;
          .image {
            width: 218px;
            height: 218px;
            background: #d8d8d8;
            border-radius: 8px;
          }
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 28px;
            color: #242931;
            line-height: 40px;
            text-align: left;
          }
          .price {
            .unit {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 24px;
              color: #fe2b27;
              line-height: 33px;
              text-align: left;
            }
            .money {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 36px;
              color: #fe2b27;
              line-height: 50px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.card {
  background: #ffffff;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  .header {
    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #1d2129;
      line-height: 40px;
      text-align: left;
      font-style: normal;
    }
    .subTitle {
      margin-top: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #86909c;
      line-height: 33px;
      text-align: left;
      font-style: normal;
    }
  }
  .discountLine {
    display: flex;
    margin-top: 9px;
    margin-bottom: 24px;
    .discount {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: rgba(254, 81, 0, 0.1);
      border-radius: 8px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #fe5100;
      line-height: 33px;
      text-align: left;
      font-style: normal;
      padding: 12px 8px;
      .helpIcon {
        margin-left: 6px;
      }
    }
  }
  .form {
    background: #f7f8f9;
    border-radius: 16px;
    overflow: hidden;
    --adm-color-background: #f7f8f9;
    --prefix-width: 35vw;
    :global {
      .adm-list-body {
        border: none;
      }
    }
    .noDiscount {
      --adm-color-text-secondary: #1d2129;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #1d2129;
      line-height: 40px;
      text-align: left;
      font-style: normal;
      .input {
        --color: #1d2129;
        --font-size: 28px;
        input[placeholder] {
          font-size: 28px;
        }
      }
      :global {
        .adm-list-item-content-main,
        .adm-list-item-content-prefix {
          padding: 24px 0;
        }
      }
    }
    .totalMoney {
      --adm-color-text-secondary: #1d2129;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 40px;
      color: #1d2129;
      line-height: 56px;
      font-style: normal;
      .input {
        --color: #F2302F;
        font-weight: 600;
        --font-size: 40px;
        input::placeholder {
          font-size: 28px;
        }
      }
      :global {
        .adm-list-item-content-prefix {
          padding: 32px 0px 24px;
        }
        .adm-list-item-content-main {
          padding: 32px 0px 24px;
        }
      }
    }
    .input {
      --text-align: right;
      --placeholder-color: #86909c;
      position: relative;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
.footer {
  margin-top: 80px;
  .disabledBtn {
    opacity: 0.5;
  }
  .footerMsg {
    margin-top: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #86909c;
    line-height: 28px;
    text-align: center;
    font-style: normal;
  }
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 45px;
  background-color: #F2302F;
  margin-left: 2px;
  animation: blink 1s infinite steps(1);
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.totalMoney {
  .input {
    .cursor {
      height: 48px;
    }
  }
}

.numberKeyboard {
  :global(.adm-number-keyboard-key.adm-number-keyboard-key-ok) {
    background-color: #F2302F;
    color: #fff;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app{
    width: 100%;
    cursor: pointer;

    .input{
        background: #F7F8F9;
        border-radius: 16px;
        width: 700px;
        height: 72px;
        color: #86909C;
        font-size: 28px;
        text-align: center;
        line-height: 72px;
        display: inline-block;
    }
}
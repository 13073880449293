$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  padding: 0 24px;
  background-color: #F4F9FF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
}

.warningBox {
  margin: 48px 16px 0;
  color: #1D2129;
  font-size: 28px;
  font-weight: 500;

  .title {
    font-size: 48px;
  }

  .warningTitle {
    display: flex;
    align-items: flex-start;

    .warningIcon {
      font-size: 32px;
      margin-right: 8px;
      margin-top: 2px;
    }

    span {
      font-size: 28px;
    }
  }

  .warningText {
    font-size: 28px;
    line-height: 1.5;
    margin-left: 40px;
  }
}

.optionsList {
  background-color: #fff;
  box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.03);
  border-radius: 16px;
  padding: 8px 0;
  overflow: hidden;
  margin: 32px 0 24px;

  .optionItem {
    padding: 24px;
    display: flex;
    align-items: flex-start;

    &:last-child {
      border-bottom: none;
    }

    .dot {
      width: 12px;
      height: 12px;
      background-color: #000;
      border-radius: 50%;
      margin-right: 12px;
      margin-top: 13px;
    }

    .optionContent {
      flex: 1;

      .optionTitle {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .optionDesc {
        font-size: 24px;
        color: #999;
      }
    }
  }
}

.bottomFixedContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F4F9FF;
  padding: 24px 24px 48px;
}

.verifyButtons {
  .verifyButton {
    margin-bottom: 24px;
    height: 88px;
    border-radius: 46px;
    font-size: 28px;
    font-weight: 500;
  }
}

.contentContainer {
  flex: 1;
  padding-bottom: 240px; /* Add enough padding to prevent content from being hidden behind fixed bottom container */
} 
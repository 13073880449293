$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 36px;
    color: #1d2129;
    line-height: 50px;
    text-align: right;
    font-style: normal;
    margin-bottom: 50px;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.card {
  background: #ffffff;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  .header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #1d2129;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    padding-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
  }
  .content {
    padding-top: 24px;
    .item {
      position: relative;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 28px;
      color: #1d2129;
      line-height: 48px;
      text-align: left;
      font-style: normal;
      padding-left: 24px;
      &::before {
        position: absolute;
        content: "";
        display: block;
        top: calc(50% - 6px);
        left: 0px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #1d2129;
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  background-size: 100% auto;
  background-repeat: no-repeat;
  .content {
    position: relative;
    top: -50px;
    background-color: transparent;
    padding: 0 16px;
    .activitySpace {
      --gap-vertical: 16px;
    }
  }
}

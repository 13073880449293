$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.card {
  --gap: 16px;
  .row {
    .col {
      display: flex;
      align-items: center;
      justify-content: center;
      .image {
        width: 222px;
        height: 152px;
        border-radius: 16px;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        padding: 12px 16px;
        box-sizing: border-box;
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 28px;
          color: #1173b2;
          line-height: 40px;
          text-align: left;
        }
        .subTitle {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #1173b2;
          line-height: 22px;
          text-align: left;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 16px;
    background-color: #fff;
    .footerIcon {
      flex: none;
      height: 32px;
      width: 122px;
      margin-right: 16px;
    }
    .text {
      :global {
        .adm-ellipsis {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #171a1d;
          line-height: 42px;
          text-align: left;
        }
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.verificationButton {
  font-size: 28px;
  color: #1777FF;
  white-space: nowrap;
  padding-left: 32px;
  
  &:disabled {
    color: #999;
  }
} 
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.orderOnline {
  padding: 24px;
  background: linear-gradient(180deg, #f92f2d 0%, #f6f7f8 300px, #f5f6f8 330px);
  .header {
    .title {
      font-family: GenSenMaruGothicTW-Bold, GenSenMaruGothicTW-Bold;
      font-weight: normal;
      font-size: 36px;
      color: #fff7f8;
      line-height: 50px;
      text-align: right;
      font-style: normal;
      margin-right: 12px;
    }
    .subTitle {
      font-family: GenSenMaruGothicTW-Light, GenSenMaruGothicTW-Light;
      font-weight: normal;
      font-size: 30px;
      color: #fff7f8;
      line-height: 41px;
      text-align: right;
      font-style: normal;
    }
    margin-bottom: 24px;
  }
  .content {
    background: linear-gradient(165deg, #ffeff1 0%, #ffffff 100%);
    border-radius: 24px;
    padding: 32px 15px;
    .block {
      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        line-height: 42px;
        text-align: left;
        font-style: normal;
        margin-bottom: 32px;
        padding-left: 24px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 8px;
          height: 26px;
          background: #f2302f;
          border-radius: 4px;
          left: 0;
          // 垂直居中
          top: calc(50% - 13px);
        }
      }
      .list {
        --gap-vertical: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // margin-bottom: 16px;

          .img {
            width: 120px;
            height: 120px;
            // margin-bottom: 24px;
            // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
            // border-radius: 42px;
            // border: 4px solid #ffffff;
          }
          .itemTitle {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 24px;
            color: #333333;
            line-height: 33px;
            text-align: right;
            font-style: normal;
            // margin-bottom: 2px;
          }
          .itemSubTitle {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 24px;
            color: #86909c;
            line-height: 33px;
            text-align: right;
            font-style: normal;
          }
        }
      }
    }
  }
}

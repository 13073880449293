$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
@import "@/styles/_mixins.scss";
.page {
  @include background-image(
    "%E8%83%8C%E6%99%AF-c6e2d89a-52bc-4b55-b972-1c2d044e2c99.png"
  );
  height: 100%;
  overflow-y: auto;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #112a3f;
  background-position: center;
  padding: 0 24px 0;
  box-sizing: border-box;
  .container {
    padding: 250px 24px 0;
    @include background-image("1-3f40fc91-dc4a-47cd-8c9e-4fff56e58947.png");
    background-size: auto 414px;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .content {
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    .tab {
      :global {
        .adm-tabs-header {
          border-radius: 24px;
        }
        .adm-tabs-header-mask,
        .adm-tabs-tab-list {
          border-radius: 24px;
        }
        .adm-tabs-tab-wrapper {
          flex: auto;
        }
      }
    }
    .qrContainer {
      padding: 48px;

      .qrTitle {
        font-family: AlibabaPuHuiTi_2_65_Medium;
        font-size: 36px;
        color: #1d2129;
        line-height: 50px;
        text-align: center;
        font-style: normal;
        padding-bottom: 68px;
        font-weight: bold;
        background-size: 304px auto;
        background-position: center bottom;
        background-repeat: no-repeat;
        @include background-image(
          "%E7%BC%96%E7%BB%84-473357C5-C971-430D-AD9D-E0F0D04BFE04.png"
        );
      }
      .qrCodeBody {
        // background: linear-gradient(
        //   180deg,
        //   rgba(24, 121, 255, 0) 0%,
        //   rgba(24, 121, 255, 0.1) 49%,
        //   rgba(24, 121, 255, 0) 100%
        // );
        background: linear-gradient(
          180deg,
          rgba(6, 35, 58, 0) 0%,
          rgba(6, 35, 58, 0.1) 49%,
          rgba(6, 35, 58, 0) 100%
        );
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .qrCodeImage {
          width: 480px;
          height: 480px;
        }
      }
      .qrCodeText {
        margin-top: 16px;
        margin-bottom: 65px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #86909c;
        line-height: 33px;
        text-align: center;
        font-style: normal;
      }
    }
  }
  .actionList {
    margin-top: 16px;
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 32px;
    .action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #fff;
      padding: 30px 32px;
      box-sizing: border-box;
      &:active {
        background-color: #f5f7fa;
      }
      .actionContent {
        display: flex;
        align-items: center;
        flex: 1;
        .icon {
          width: 44px;
          height: 44px;
          margin-right: 24px;
        }
        .actionText {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 28px;
          color: #1d2129;
          line-height: 40px;
          text-align: left;
          font-style: normal;
        }
      }
      .actionIcon {
        width: 36px;
        height: 36px;
        transform: rotate(180deg);
      }
    }
  }
}
.button {
  --background-color: linear-gradient(
    169deg,
    #697480 0%,
    #06233a 100%
  ) !important;
  box-shadow: inset 0px 2px 12px 2px rgba(255, 255, 255, 0.5) !important;
}
.warningTip {
  display: flex;
  align-items: first baseline;
  margin: 6px 16px 0;
  margin-bottom: -48px;

  background: #fff9ed;
  padding: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #ff6010;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  .icon {
    padding-top: 2px;
    .warningIcon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
  .warningText {
  }
}

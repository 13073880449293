$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.firstPageItem {
  padding-top: 24px;
}

.swiper {
  width: 100%;
  height: 298px;
  border-radius: 24px;

  .swiperItem {
    height: 100%;
    border-radius: 24px;
    width: 100%;
    height: 298px;
  }
}
.swiperHide {
  display: none;
}
.featuredGrid {
  --gap: 26px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}
.productGrid {
  --gap-horizontal: 18px;
  // margin-bottom: 24px;
  .productGridItem {
    margin-bottom: 24px;
  }
}
.content {
  background: #f5f5f5;
}
.tab {
  position: sticky;
  top: 0;
  z-index: 1000;
  .outTabs {
    --active-line-color: rgb(242, 48, 47);
  }

  .activeTitleClassName {
    color: rgb(242, 48, 47);
    --adm-color-primary: rgb(242, 48, 47, 0.1);
  }
}
.fullScreenTab {
  position: sticky;
  top: 0;
  // top: calc(var(--header-padding-top) + var(--header-title-height));
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 150px);
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vh;
  font-size: 24px;
  color: #c4c4c4;
}
.indexLoading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
body {
  background: #f4f9ff;
}

.app {
  width: 700px;
  margin: 0 auto;

  ul,
  li {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
}

.banner {
  width: 100%;
  height: 240px;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}




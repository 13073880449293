$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  min-height: 108px;
  // min-height: 100px;
  width: 100%;
  overflow: hidden;
  margin-top: 16px;
  text-align: left;
  border-radius: 24px;

  .thirdPartyContainer {
    --padding-left: 32px;
    height: 108px;
    font-size: 28px;
    background: #fff !important;

    :global(.adm-list-item-content-prefix) {
      --prefix-padding-right: 20px;      
    }

    :global(.adm-list-item-content-main) {
      padding: 32px 0;
    }

    :global(.adm-list-item-content-extra) {
      padding-right: 10px;
    }

    .alipay {
      width: 40px;
      height: 40px;
      vertical-align: bottom;
    }
  
    .weixinpay {
      width: 40px;
      height: 40px;
      vertical-align: bottom;
      position: relative;
      left: -2px;
    }
  
    .unionpay {
      width: 40px;
      height: 40px;
      vertical-align: bottom;
    }

    .checkbox {
      &:global(.adm-checkbox-checked) {
        :global(.adm-checkbox-icon) {
          background-color: #F2302F !important;
          border-color: #F2302F !important;
        }
      }
    }
  }
}

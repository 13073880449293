$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.loading {
  position: relative;
  .spin {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .fullScreen {
    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;
    transform: none;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.formCard {
  padding: 24px 32px 12px;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #1d2129;
    line-height: 45px;
    text-align: left;
  }
  .subTitle {
    margin-top: 7px;

    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #86909c;
    line-height: 33px;
    text-align: left;
  }
  & + div {
    :global {
      .adm-list-item-content {
        border: none;
      }
    }
  }
}

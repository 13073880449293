$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;
  overflow-y: auto;
  height: 100px;
  display: flex;
  flex-flow: row;
  align-items: center;
  border-radius: 24px 24px 0px 0px;
  margin-top: -20px;
  background: #fff6db;

  .item {
    width: 145px;
    height: 48px;
    border-radius: 24px;
    background: #fff;
    display: inline-block;
    margin-left: 20px;
    text-align: center;
    line-height: 48px;
    color: #A14E44;
    cursor: pointer;
  }

  .activate {
    color: #fff;
    background: linear-gradient(to top, #FF3452, #FF671D);
  }
}

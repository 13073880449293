$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  position: relative;
  height: 100vh;
  overflow-y: auto;

  .topSearch {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 24px;
    .searchBar {
      --background: #eaebed;
      --border-radius: 32px;
      --height: 64px;
      --placeholder-color: #86909c;
      // --padding-left: calc(50% - 92px);
    }
  }
  .content {
    background-color: #f5f5f5;
    // padding: 0 0px 0 24px;
    height: calc(100% - 64px - 48px - env(safe-area-inset-bottom));
    :global {
      .adm-index-bar-anchor {
        background-color: #f5f5f5;
      }
    }
    .indexBar {
      background-color: transparent;
      :global {
        .adm-index-bar-anchor-title {
          text-align: left;
          padding-left: 40px;
        }
        .adm-index-bar-sidebar {
          width: 48px;
          right: 8px;
          .adm-index-bar-sidebar-row {
            // text-align: center;
            // width: 48px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            padding: 0;
          }
        }
      }
    }
    .personList {
      border-radius: 16px;
      background-color: #fff;
      overflow: hidden;
      margin-right: 48px;
      margin-left: 24px;
      .swipeAction {
        :global {
          .adm-button {
            padding-left: 24px;
            padding-right: 24px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 28px;
            color: #ffffff;
            line-height: 40px;
            text-align: left;
            font-style: normal;
          }
        }
      }
      .person {
        padding: 24px 0;
        margin: 0 24px;
        border-bottom: 1px solid #f2f3f5;

        .personInfo {
          display: flex;
          // flex-direction: ;
          align-items: center;
          justify-content: space-between;
          .personInfoContentLeft {
            display: flex;
            align-items: center;

            .avatar {
              margin-right: 16px;
              width: 60px;
              height: 60px;
              border-radius: 50%;
            }
            .avatarName {
              width: 60px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: #1f86ff;
              color: #fff;
              font-size: 28px;
              margin-right: 16px;
            }
            .personInfoContent {
              text-align: left;
              .personInfoTitle {
                align-items: center;
                justify-content: space-between;
                .personInfoName {
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 500;
                  font-size: 28px;
                  color: #1d2129;
                  line-height: 40px;
                  text-align: left;
                  font-style: normal;
                }
                .adminTip {
                  padding: 2px 8px;
                  background: #ffffff;
                  border-radius: 8px;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 20px;
                  color: #1777ff;
                  line-height: 28px;
                  text-align: left;
                  font-style: normal;
                  border: 2px solid rgba(23, 119, 255, 0.2);
                }
                .myTip {
                  padding: 2px 8px;
                  background: #ffffff;
                  border-radius: 8px;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 20px;
                  color: #fe5100;
                  line-height: 28px;
                  text-align: left;
                  font-style: normal;
                  border: 2px solid rgba(254, 81, 0, 0.2);
                }
                .stopTip {
                  padding: 2px 8px;
                  background: #ffffff;
                  border-radius: 8px;
                  border: 2px solid #eaebed;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 20px;
                  color: #86909c;
                  line-height: 28px;
                  text-align: left;
                  font-style: normal;
                }
              }
              .personInfoSubTitle {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 24px;
                color: #86909c;
                line-height: 33px;
                text-align: left;
                font-style: normal;
              }
            }
          }
          .personInfoActionIcon {
            width: 24px;
            height: 24px;
            // 旋转180
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .action {
    z-index: 901;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: calc(24px + env(safe-area-inset-bottom));
    right: 24px;
    border-radius: 50%;
    width: 96px;
    height: 96px;
    background: #06233a;
    .icon {
      color: #fff;
      font-size: 32px;
      font-weight: bold;
    }
  }
}

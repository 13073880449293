$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  // flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 24px 24px 0;
  box-sizing: border-box;
  background: #f4f9ff;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    // top: calc(0px - var(--header-padding-top) - var(--header-title-height));
    left: 0;
    width: 100%;
    height: 100vh;
    // z-index: 1;

    width: 100%;
    background: radial-gradient(circle, rgba(39, 130, 250, 0.7), transparent)
        130% -10% / 330px 330px no-repeat,
      radial-gradient(circle, rgba(173, 204, 244, 0.6), transparent) 0% 30% /
        770px 770px no-repeat,
      radial-gradient(circle, rgba(150, 157, 210, 0.2), transparent) 0% 50% /
        330px 330px no-repeat,
      radial-gradient(circle, rgba(194, 200, 247, 0.2), transparent) 100% 50% /
        770px 770px no-repeat,
      radial-gradient(circle, rgba(125, 166, 221, 0.2), transparent) 50% 90% /
        700px 700px no-repeat;
    filter: blur(100px);
  }
  .header {
    display: flex;
    align-items: center;

    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #242931;
    line-height: 45px;
    text-align: left;
    font-style: normal;
    z-index: 1;
    position: relative;
    .title {
    }
    .icon {
      margin-left: 14px;
      width: 14px;
      height: 14px;
    }
  }
  .tab {
    margin-bottom: 24px;
    margin-top: 18px;

    :global {
      .adm-tabs-tab-list {
        background-color: transparent;
      }
      .adm-tabs-tab-wrapper {
        flex: 1;
      }
    }
    .tabTitle {
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 1;
    position: relative;

    .table {
      --col-gap: 50px;
      width: 100%;
      flex: 1;
      .tableBody {
        max-height: calc(100vh - 350px);
        overflow: auto;
      }
    }
  }
}

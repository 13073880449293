$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.radioGroup {
  display: flex;
  flex-direction: column;
  .radio {
    color: #1d2129;
    display: flex;
    width: 100%;
    line-height: 40px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    :global {
      --font-size: 28px;
      --gap: 16px;
      --icon-size: 32px;
    }
  }
}

.upload {
  --cell-size: 100%;
  :global {
    .adm-space-item {
      width: 100%;
      height: 360px;
      &:last-child {
        height: auto;
      }
    }
  }
  .addUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 360px;
    background: #ffffff;
    border-radius: 16px;
    border: 2px dashed #e5e6eb;
    .addIcon {
      font-size: 28px;
      width: 28px;
      height: 28px;
      color: #86909c;
    }
    .addUploadText {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #86909c;
      line-height: 33px;
      text-align: left;
      font-style: normal;
    }
  }
}
.form {
  :global {
    .adm-list-body {
      border: none;
      .adm-form-item-label {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #4e5969;
      }
    }

    .adm-list-item {
      .adm-list-item-content {
        border-top: none;
        border-bottom: 1px solid #f0f1f5;
      }
      &:last-child > .adm-list-item-content {
        border-bottom: none;
      }
    }
  }
  .openLetter {
    :global {
      .adm-form-item-required-asterisk {
        display: none;
      }
      .adm-list-item-content {
        border-bottom: none;
      }
    }
  }
}
.promptInfo {
  background: #f8fafb;
  border-radius: 8px;
  padding: 24px;
  margin-top: 124px;

  .promptInfoTitle {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #1d2129;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    .promptInfoImg {
      // width: 96px;
      height: 26px;
      padding-top: 6px;
      padding-bottom: 8px;
      flex: none;
      margin-right: 8px;
    }
  }
  .promptInfoList {
    margin-bottom: 33px;
    .stepItem {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .promptImage {
    width: 100%;
    margin-bottom: 16px;
  }
  .promptImageInfo {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #86909c;
    line-height: 33px;
    text-align: center;
    font-style: normal;
  }
}
.info {
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #4e5969;
    line-height: 33px;
    text-align: left;
    font-style: normal;
  }
  .text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #86909c;
    line-height: 33px;
    text-align: left;
    font-style: normal;
  }
}
.type {
  :global {
    .adm-form-item-label {
      margin-bottom: 24px !important;
    }
  }
}

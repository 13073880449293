$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  padding: 0 24px;
  text-align: left;

  .title {
    font-size: 48px;
    font-weight: 500;
    color: #1D2129;
    margin: 48px 0 0 0;
    line-height: 68px;
    padding-left: 16px;
  }

  .forgetPassword {
    margin-left: 6px;
    color: #1777FF;
  }

  .description {
    margin: 15px 0 50px;
    font-size: 24px;
    line-height: 33px;
    color: #86909C;
    padding: 0 16px;
  }

  .formContainer {
    --adm-color-primary: #1777FF;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.03);
    padding: 24px;
    margin-bottom: 32px;
    height: 1000px;
  }

  :global(.adm-list-item-content) {
    border-bottom: 2px solid #F2F3F5;
  }

  :global(.adm-form-item-label) {
    font-size: 30px;
    color: #1D2129;
    padding-right: 24px;
  }

  :global(.adm-input) {
    font-size: 30px;
    color: #1D2129;
    --font-size: 32px;
  }

  :global(.adm-list-body) {
    border: none;
  }

  :global(.adm-list-item-content) {
    border-top: none;
  }

  :global(.adm-list-item-content-prefix) {
    width: 180px;
  }

  :global(.adm-list-card) {
    margin: 0px;
  }

  :global(.adm-list-item-content) {
    font-size: 30px;
  }

  :global(.adm-input-element) {
    font-size: 30px;


    &::placeholder {
      color:#86909C
    }
  }

  :global(.adm-form-item-feedback-error) {
    font-size: 24px;
    margin-top: 8px;
  }

  :global(.adm-form-footer) {
    padding: 80px 0 56px;
  }

  .submitButton {
    width: 100%;
    height: 88px;
    background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
    border-radius: 46px;
    font-weight: 500;
    font-size: 28px;
    color: #FFFFFF;
    line-height: 40px;
    margin-top: 16px;
    border: none;

    &:active {
      opacity: 0.5;
    }
  }

  .verifyCodeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
  }
  
  .getCodeBtn {
    font-size: 28px;
    white-space: nowrap;
    padding: 0 12px;
    
    &:active {
      opacity: 0.8;
    }
  }

  .agreementSection {
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  
  .agreementText {
    font-size: 24px;
    color: #86909C;
    margin-left: 8px;
  }
  
  .agreementLink {
    color: #1677ff;
    text-decoration: none;
  }
  
  .agreementCheckbox {
    transform: scale(0.8);
    margin-right: -8px;
  }  
}

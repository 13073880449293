$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  //   position: relative;
  //   overflow-y: auto;
  //   display: flex;
  //   flex-direction: column;
  //   flex: 1;
  width: 100%;
  .bannerContainer {
    position: relative;
    .banner {
      // background-color: #09f;
      width: 100%;
    }
    .map {
      position: absolute;
      top: 8px;
      left: 24px;
      // width: 100%;
      // height: 100%;
      z-index: 1;
      .location {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 328px;
        .locationContent {
          display: flex;
          align-items: center;

          // width: 260px;

          .mapIcon {
            width: 32px;
            height: 32px;
            color: #fffdfc;
            margin-right: 4px;
          }
          .mapText {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: #fffdfc;
            line-height: 37px;
            text-align: left;
            font-style: normal;
          }
        }
        .openIcon {
          width: 32px;
          height: 32px;
          color: #fffdfc;
          transform: rotate(-90deg);
        }
      }
    }
  }
  .tabs {
    // flex: 1;
    // height: 100%;
    position: sticky;
    top: 0;
    --active-line-border-radius: 4px;
    --active-line-color: #f2302f;
    --active-line-height: 6px;
    --active-title-color: #1d2129;
    --content-padding: 0px 40px;
    --fixed-active-line-width: 40px;
    --title-font-size: 28px;
    background-color: #fffdfc;
    border-radius: 24px 24px 0px 0px;
    overflow: hidden;
    margin-top: -30px;
    :global {
      .adm-tabs-header {
        border-bottom: none;
        height: 80px;
      }
      //   .adm-tabs-content {
      //     height: calc(100% - 80px);
      //     overflow-y: auto;
      //   }
      .adm-tabs-tab {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #242931;
        line-height: 40px;
        text-align: left;
        font-style: normal;
      }
      .adm-tabs-tab-active {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #1d2129;
        line-height: 45px;
        text-align: right;
        font-style: normal;
      }
    }
  }
  .content {
    background-color: #fffdfc;
    padding: 10px 40px 0;
  }
  .hideContent {
  }
}

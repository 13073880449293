$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 700px;
  margin: 0 auto;
  text-align: left;
}

body {
  background: #f4f9ff;
}

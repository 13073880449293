$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  display: flex;          /* 使用Flexbox布局 */
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  height: 100%;

  .image {
    width: 160px;
    height: 160px;
  }

  .tips {
    font-size: 28px;
    color: #86909C;
    margin: 16px;
  }
}

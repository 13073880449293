$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #E9F1FF;
  background-image: url('https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/xmvbazjhy6.png');
  background-size: 100%;
  background-position: center -176px;
  background-repeat: no-repeat;
}

.container {
  width: 702px;
  padding-top: 434px - 176px;
  margin: 0 auto;
}

.primaryBtn {
  border: 0 none;
  background: linear-gradient(269deg, #33AFFF 0%, #1777FF 100%);
}

.invite {
  padding: 0 32px;
  height: 726px;
  background: #FFFFFF;
  border-radius: 16px;
  text-align: center;
}

.title {
  margin-bottom: 24px;
  line-height: 45px;
  font-size: 32px;
  font-weight: 600;
  color: #131415;
}

.method {
  padding: 38px 0;
  margin-bottom: 10px;
  border-bottom: 4px solid #F2F3F5;
  &:last-child {
    border-bottom: none;
  }
}

.content {
  margin: 0 auto;
}

.footer {
  height: 350px;
  margin-top: 14px;
  background-image: url('https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/u7j6kaxgn6.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.registerContainer {
  width: 100%;
  min-height: 100vh;
  background-color: #F4F9FF;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 24px;
  position: relative;
}

.title {
  flex: 1;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  margin: 0;
  color: #333;
}

.registerCard {
  background: #ffffff;
  border-radius: 24px;
  margin: 24px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  :global {
    .adm-form {
      padding: 40px 24px 24px;

      .adm-list-body {
        border: none;
      }
    }
    
    .adm-form-item {
      margin-bottom: 24px;
    }

    .adm-list-item-content-main {
      padding: 0;
    }

    .adm-list-item-content {
      border: none;
    }

    .adm-input {
      font-size: 28px;
      color: #86909C;
      line-height: 104px;
      height: 104px;
    }

    .adm-input-element {
      height: 48px;
      font-size: 28px;
      &::placeholder {
        color:#86909C
      }
    }

    .adm-form-item-extra {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }

    .adm-form-footer {
      padding: 0 0 20px 0;
    }

    .adm-form-item-feedback-error {
      margin: 0;
      color: #ff4d4f;
      font-size: 24px;
    }

    .adm-checkbox {
      --icon-size: 36px;
      --font-size: 28px;
      --gap: 16px;
    }

    .adm-form-item-child {
      padding-left: 0;
      background: #F5F7FB;
      border-radius: 52px;
      height: 104px;
    }
  }
}

.labelWrapper {
  font-size: 28px;
  color: #1D2129;
  font-weight: 500;

  .labelText {
    margin-left: 4px;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 40px;
}

.codeInput {
  .inputWrapper {
    padding-right: 0;
  }
}

.inputIcon {
  font-size: 40px;
  color: #86909C;
  margin-right: 16px;
}

.customInput {
  flex: 1;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 28px;
  color: #333;
}

.verifyCodeButton {
  display: flex;
  align-items: center;
  height: 30px;
  border-left: 2px solid #C9CDD4;
  padding-right: 24px;
}

.registerButton {
  height: 88px;
  font-size: 28px;
  font-weight: 500;
  margin-top: 40px;
  background: linear-gradient(269deg, #33AFFF 0%, #1677ff 100%);
  border-radius: 46px;
  border: none;

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.eye {
  padding-right: 10px;
}

.agreementSection {
  display: flex;
  align-items: center;
  margin-top: 100px;
  padding: 0 8px;
}

.agreementText {
  font-size: 24px;
  color: #86909C;
  margin-left: 8px;
}

.agreementLink {
  color: #1677ff;
  text-decoration: none;
}

.agreementCheckbox {
  transform: scale(0.8);
  margin-right: -8px;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.space {
  --gap-vertical: 12px;
  background: rgba(216, 11, 11, 0.08);
  border-radius: 16px;
  padding: 16px;
}
.productGrid {
  --gap-horizontal: 34px;
  .productImg {
    width: 100%;
    height: 132px;
    border-radius: 8px;
    object-fit: cover;
  }
  .price {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #fc421d;
    line-height: 45px;
    text-align: center;
    margin-top: 8px;
    .num {
      .integer {
      }
      .decimal {
        font-size: 24px;
      }
    }

    .unit {
      font-size: 24px;
      line-height: 33px;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #1d2129;
    line-height: 40px;
  }
  .more {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #8a8a8a;
    line-height: 33px;
  }
}

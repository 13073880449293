$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  text-align: left;

  .switchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 66px 40px 0 40px;

    .switchTitle {
      font-size: 48px;
      font-weight: 500;
      font-family: PingFangSC, PingFang SC;
      color: #1D2129;
    }
  }

  .tips {
    margin: 18px 0;
    font-size: 24px;
    padding: 0 40px;
    color: #86909C;

    .changePassword {
      font-size: 24px;
    }
  }

  .passwordContainer {
    padding: 16px 40px 16px 40px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.03);
    border-radius: 16px;
    margin: 50px 24px 0 24px;
  }
}

.dialogGrid {
  padding-top: 40px;
}
.dialogContainer {
  :global(.adm-center-popup-wrap) {
    width: 82vw;
  }
  :global(.adm-modal-title) {
    margin-top: 24px;
  }
  :global(.adm-modal-title) {
    margin-bottom: 24px;
  }
  .dialogContent {
    .dialogTitle {
      font-size: 28px;
      color: #86909C;
      text-align: center;
      margin-bottom: 48px;
    }
    .backBtn {
      width: 100%;
      height: 88px;
      background: #FFFFFF;
      border-radius: 46px;
      border: 2px solid #E5E6EB;
      font-weight: 500;
      font-size: 28px;
      color: #1D2129;
      line-height: 40px;
    }
    
    .continueBtn {
      width: 100%;
      height: 88px;
      background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
      border-radius: 46px;
      font-weight: 500;
      font-size: 28px;
      color: #FFFFFF;
      line-height: 40px;
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .content {
    flex: 1;
    .map {
      position: relative;
      .iconDingwei {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 15px;
        padding: 10px;
      }
      .container {
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 50vh;
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.formItem {
  --padding-left: 32px;
  --padding-right: 32px;
  --label-color: #4e5969;
  :global {
    .adm-list-item-content-prefix,
    .adm-list-item-content-main {
      padding-top: 27px;
      padding-bottom: 27px;
    }
    .adm-list-item-content-prefix {
      height: 40px;
    }
    .adm-form-item-label {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 28px;
      color: var(--label-color);
      line-height: 40px;
      text-align: left;
      font-style: normal;
      height: 40px;
    }
  }
}

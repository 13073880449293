$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
// 定义全局变量
@import "./_variables.scss";
@import "./_mixins.scss";
// body {
//   @include background-image(
//     "%E7%BC%96%E7%BB%84%2029%402x-270f7e90-4eb3-4622-a172-2779278e093f.png"
//   );
//   background-color: $primary_color;
// }
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}
html,
#root {
  width: 100%;
  height: 100%;
}

ul,
li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  margin-top: 300px;
}

.btn {
  display: inline-block;
  background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
  border-radius: 46px;
  width: 286px;
  height: 88px;
  text-align: center;
  line-height: 88px;
  color: #fff;
  font-size: 32px;
  margin-top: 20px;
}
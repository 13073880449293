$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.01); 
  width: 100%;
  border-radius: 24px;
  background: #fff;
  overflow: hidden;
  margin-top: 64px;

  .header {
    font-family: PingFangSC, PingFang SC;
    color: #131415;
    font-size: 32px;
    font-weight: 600;
    padding: 50px 0px 41px 0px;
    border-bottom: dashed 2px #f2f3f5;
    margin:0 40px 41px;

    .amount {
      font-size: 56px;
      margin-left: 8px;
    }
  }

  .list {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: 28px;
    box-sizing: border-box;
    padding: 16px 40px;

    .label {
      color: #86909c;
    }
    .value {
      color: #1d2129;
    }
  }

  .discount {
    .label {
      color: #FE5100;
    }
    .value {
      color: #FE5100;
    }
  }

  .footer{
    margin: 64px 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
  }

  .btn{
    width: 286px;
    height: 88px;
  }

  .btnPrimary{
    width: 286px;
    height: 88px;
    background: linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%);
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.result {
  flex: 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: 32px;
  box-sizing: border-box;
  .image {
    width: 164px;
    height: 164px;
    margin-top: 218px;
  }
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #1d2129;
    line-height: 45px;
    margin-top: 24px;
  }
  .info {
    margin-top: 15px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 28px;
    color: #86909c;
    line-height: 40px;
  }
  .failButton {
    margin-top: 64px;
  }
  .success {
    background: #f8fafb;
    border-radius: 8px;
    width: 100%;
    padding: 24px 32px;
    // margin-top: 48px;
    box-sizing: border-box;
    .successTitle {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #1d2129;
      line-height: 40px;
      margin-bottom: 32px;
    }
    .successRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .successLabel {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #4e5969;
        line-height: 33px;
        text-align: left;
      }
      .successValue {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #1d2129;
        line-height: 33px;
        text-align: right;
      }
    }
  }
}
.copy {
  width: 28px;
  height: 28px;

  vertical-align: middle;
  margin-left: 16px;
}

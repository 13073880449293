$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 700px;
  margin: 0 auto;

  .success {
    color: #1d2129;
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
    padding-top: 120px;
  }

  .fill {
    color: #F2302F;
    font-size: 40px;
    margin-right: 5px;
  }

  .sub {
    color: #86909c;
    font-size: 28px;
    text-align: center;
    margin-top: 16px;
  }
}

body {
  background: #f4f9ff;
}

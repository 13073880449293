$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  .banner {
    width: 100%;
  }
  .content {
    position: relative;
    top: -16px;
    padding: 20px;
    z-index: 1;
    background: #f3f6f8;
    border-radius: 16px 16px 0px 0px;
    .title {
      font-family: DOUYINSANSBOLD, DOUYINSANSBOLD;
      font-weight: bold;
      font-size: 36px;
      color: #242931;
      line-height: 43px;
      text-align: left;
      margin-bottom: 17px;
    }
    .productList {
      --gap-vertical: 16px;
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.product {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 16px;
  padding-bottom: 24px;
  .image {
    // width: 342px;
    height: 336px;
    object-fit: cover;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-bottom: 16px;
  }
  .title {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 26px;
    color: #333333;
    line-height: 37px;
    word-break: break-word;
    padding: 0 16px;
    text-align: left;
    :global {
      .adm-ellipsis {
        line-height: 37px;
      }
    }
  }
  .footer {
    padding: 0 16px;
    text-align: left;
    display: flex;
    // width: 100%;
    // justify-content: space-between;
    align-items: center;
    .price {
      margin-right: 18px;
      .unit {
        font-weight: 500;
        font-size: 24px;
        color: #fc421d;
        line-height: 33px;
        margin-right: 7px;
      }
      .amount {
        font-weight: 500;
        font-size: 36px;
        color: #fc421d;
        line-height: 50px;
      }
    }
    .sales {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #86909c;
      line-height: 33px;
      // text-align: right;
      font-style: normal;
      .label {
        margin-right: 5px;
      }
    }
  }
  .tags {
    margin-top: 8px;
    padding: 0 16px;

    .tag {
      border-radius: 4px;
      border: 1px solid #fe2b27;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #fe2b27;
      line-height: 20px;
      padding: 4px;
    }
  }
}

.productHor {
  display: flex;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  .image {
    flex: none;
    width: 220px;
    height: 220px;
    border-radius: 12px;
    margin-right: 24px;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #242931;
      line-height: 40px;
      text-align: left;
      :global {
        .adm-ellipsis {
          line-height: 37px;
        }
      }
    }
    .footer {
      .info {
        padding: 0 16px;
        text-align: left;
        display: flex;

        align-items: center;
        .price {
          margin-right: 18px;
          .unit {
            font-weight: 500;
            font-size: 24px;
            color: #fc421d;
            line-height: 33px;
            margin-right: 7px;
          }
          .amount {
            font-weight: 500;
            font-size: 36px;
            color: #fc421d;
            line-height: 50px;
          }
        }
        .sales {
          .label {
            margin-right: 5px;
          }
        }
      }
      .tags {
        width: 100%;
        margin-top: 8px;
        padding: 0 16px;

        .tag {
          border-radius: 4px;
          border: 1px solid #fe2b27;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #fe2b27;
          line-height: 20px;
          padding: 4px;
        }
      }
    }
  }
}

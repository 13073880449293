$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  :global(.adm-dropdown-item) {
    justify-content: flex-start;
    color: #187AFF;
    font-weight: 500;

    :global(.adm-dropdown-item-title-arrow) {
      color: #187AFF;
    }
  }
}

.selectorContainer {
  padding: 24px;
}

.selectorTitle {
  color: #4E5969;
  margin-bottom: 17px;
  font-size: 24px;
}

.dateInput {
  background-color: #F7F8F9;
  border-radius: 16px;
  height: 72px;
  line-height: 72px;
  --text-align: center;
}

.dateSeparator {
  margin: 0 10px;
  border-bottom: 2px solid #1D2129;
  height: 35px;
}

.resetButton {
  background: #FFFFFF;
  border-radius: 44px;
  border: 2px solid #E5E6EB;
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  text-align: left;
}

.orderSubject {
  font-size: 28px;
  color: #1D2129;
  font-weight: 500;
}

.orderRightOutline {
  font-weight: 800;
}

.avatarByName {
  width: 60px;
  height: 60px;
  display: flex;
  background: #1F86FF;
  color: #fff;
  font-size: 28px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 60px;
}

.orderList {
  background-color: rgb(245, 245, 245);
  text-align: left;
  margin: 8px 24px;
  
  :global(.adm-list-body) {
    border-radius: 16px;
  }

  :global(.adm-list-item-content-extra) {
    font-size: 28px;
    color: #FF4B33;
    font-weight: 500;
  }
}

.orderDiscount {
  font-size: 28px;
  color: #131415;
}

.billItem {
  
  :global(.adm-list-item-content-extra) {
    display: flex;
    align-self: flex-start;
    padding-top: 28px;
  }

  :global(.adm-list-item-content-main) {
    flex: 1;
    overflow: hidden;
  }
}

.billAmount {
  font-size: 28px;
  color: #131415;
  font-weight: 500;
  text-align: right;

  .refundText {
    color: #FF4B33;
    text-align: right;
    font-weight: 400;
  }
}

.refundAmount {
  color: #FF4B33;
}

.billSubject {
  font-size: 28px;
  color: #1d2129;
  font-weight: 500;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.billFirstItem {
  border-bottom: 1px solid #eee;
  margin-left: 24px;
  --padding-left: 0;
}

.billMonth {
  font-size: 28px;
  color: #86909C;
}
$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.successInfo {
  background: #f8fafb;
  border-radius: 8px;
  padding: 24px 32px;
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      flex: none;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #4e5969;
      line-height: 33px;
      text-align: left;
      font-style: normal;
      margin-right: 12px;
    }
    .value {
      flex: 1;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #1d2129;
      line-height: 33px;
      text-align: right;
      font-style: normal;
    }
  }
}

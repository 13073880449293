$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.checkboxGroup {
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}
.checkbox {
  --gap: 16px;
  --icon-size: 32px;
  --font-size: 28px;
  .checkboxLabel {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 28px;
    color: #1d2129;
    line-height: 40px;
    text-align: left;
    font-style: normal;
  }
}
.checkboxIconContainerChecked {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #1777ff;
  border-radius: 4px;
  border: 2px solid transparent;
  .checkboxIcon {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
}
.checkboxIconContainer {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #c9cdd4;
}

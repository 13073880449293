$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.app {
  width: 100%;

  .content {
    width: 100%;
    min-height: 100px;
    border-radius: 24px;
    box-sizing: border-box;
    padding: 12px;
    text-align: left;
    background: linear-gradient( 151deg, #FFFAF9 0%, #FBEEEC 100%);

    .head {
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      font-weight: bolder;

      .left,
      .right {
        color: #1D2129;
        margin-top: 6px;
        font-size: 28px;
        line-height: 50px;
        height: 44px;
        display: flex;
        flex-flow: row;
        align-items: center;
      }

      .left {
        img {
          max-width: 40px;
          max-height: 40px;
          margin-right: 16px;
          margin-left: 20px;
        }
      }

      // .right {
      //   color: #fce997;
      //   font-size: 25px;
      //   img {
      //     max-width: 50px;
      //     max-height: 50px;
      //     margin-left: 8px;
      //   }
      // }
    }
  }
}

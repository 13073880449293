$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.layout {
  text-align: left;
  background: #f4f9ff;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  scroll-behavior: smooth;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 24px;
  background: linear-gradient( 180deg, #FFF6F0 0%, #F4F9FF 100%);
  
  .content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    margin-top: 32px;
  }
}

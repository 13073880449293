$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.button-item {
  margin: 30px;
}

.title {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  margin: 20px;
}

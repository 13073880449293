$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .imageUrl {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
  }
}
.loading {
  width: 100%;
  height: 60vh;
}
.productGrid {
  --gap: 18px;
  .productGridIn {
    --gap: 16px;
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.card {
  padding: 24px;
  border-radius: 16px;
  .cardBody {
    padding: 0;
  }
  .cardContent {
    --gap-vertical: 24px;
    .cardHeader {
      text-align: left;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      --gap: 10px;
      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        line-height: 42px;
      }
      .tag {
        display: flex;
        margin: 9px 0px;
        background: rgba(238, 83, 65, 0.1);
        border-radius: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #ec3427;
        line-height: 24px;
        border: none;
        padding: 4px 12px;
      }
    }
    .iconList {
      .row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon {
          width: 96px;
          height: 96px;
        }
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #171a1d;
          line-height: 33px;
          text-align: center;
        }
      }
    }
    .hideIndicator {
      :global {
        .adm-swiper-indicator {
          display: none;
        }
      }
    }
    .bannerList {
      .image {
        width: 100%;
        height: 160px;
        border-radius: 12px;
      }
    }
  }
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.content {
  // width: 100%;
  height: 874px;
  background: transparent;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 80px;
  position: relative;
  width: 650px;
  .info {
    width: 100%;
    height: 514px;
    border-radius: 24px;
    background: #fff;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 60px 40px 60px 40px;
    box-sizing: border-box;

    .infoContent {
      padding: 0 20px 0 20px;
      .enterpriseName {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #86909c;
        line-height: 33px;
        text-align: left;
        font-style: normal;
        margin-bottom: 9px;
      }
      .balanceNo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .balanceNoText {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 48px;
          color: #1d2129;
          line-height: 67px;
          text-align: left;
          font-style: normal;
          margin-right: 16px;
        }
        .balanceNoAction {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #86909c;
          line-height: 33px;
          text-align: left;
          font-style: normal;
        }
      }
      .top {
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        .balanceName {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #86909c;
          line-height: 33px;
          text-align: left;
          font-style: normal;
          span {
            margin-right: 8px;
          }
        }

        a {
          font-size: 24px;
        }
      }
      .balance {
        font-family: PingFangSC, PingFang SC;
        color: #1d2129;
        font-size: 32px;
        margin-top: 13px;
        font-weight: 600;
        height: 78px;
        span {
          font-size: 56px;
        }
      }
    }
    .actions {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin-top: 50px; 
      border: 2px solid #E5E6EB;
      height: 88px;
      border-radius: 48px;
      .actionItem {
        font-size: 28px;
        flex: 1;
        text-align: center;
        line-height: 88px;
      }
      .divider {
        width: 2px;
        height: 32px;
        margin-top: 28px;
        background-color: #E5E6EB;
      }
    }
  }
}
.loading {
  width: 30px;
}

$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  .step {
    margin-top: 32px;
    margin-bottom: 48px;
    padding: 0 20px;
    width: 100%;
  }
  .form {
    width: 100%;
    padding: 0;
    :global {
      .adm-list-card {
        margin: 0 0 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.footer {
  width: 100%;
  position: sticky;
  bottom: env(safe-area-inset-bottom);
}

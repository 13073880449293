$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.container {
  position: relative;
  overflow: auto;
  height: 100%;
  .autoCompleteOutPut {
    overflow-y: auto !important;
    :global {
      .auto-item {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #1d2129;
        line-height: 40px;
        text-align: left;
        font-style: normal;
        padding: 28px;
      }
    }
  }

  .form {
    position: sticky;
    top: 0;
  }
}

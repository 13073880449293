$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.input {
  --font-size: 28px;
  --placeholder-color: #86909c;
  --color: #1d2129;
  min-height: 40px;

  :global {
    .adm-input-element {
      min-height: 40px;
      line-height: 1;
    }
    .adm-input-clear {
      padding: 0;
    }
  }
}

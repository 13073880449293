$primary_color: #09f;
$base_image_url: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/";
.page {
  background: #f4f9ff;
  height: 100vh;
  overflow-y: auto;
  padding: 16px 40px 0;
  box-sizing: border-box;
  .space {
    --gap: 16px;
  }
  .cardContent {
    padding-top: 24px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 64px;
  &:last-child {
    margin-bottom: 0;
  }
  .label {
    flex: none;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 28px;
    color: #1d2129;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    margin-right: 16px;
  }
  .value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #1d2129;
    line-height: 40px;
    text-align: right;
    font-style: normal;
  }
}
